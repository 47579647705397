import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import { Col, Container, Row } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import axios from "axios";
import Plot from "react-plotly.js";
import SoyabeanDiseaseDetection from "./SoyabeanDiseaseDetection";
import SoyabeanSeedDetection from "./SoyabeanSeedDetection";
const SeedDetection = () => {
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [crop, setSelectedCrop] = useState("soyabean");
  const [predictDisease, setPredictDisease] = useState();
  const [model, setModel] = useState("VGG16_best_model.h5");
  const [loading, setLoading] = useState(false)

  let crops = [
    {
      id: 1,
      name: "soyabean"
    }
  ];

  const handleInputChange = e => {
    setImagePreview(null);
    setPredictDisease();
    setSelectedCrop(e.target.value);
  };
  const handleChange = async event => {
    event.preventDefault();
    const selectedFile = event.target.files[0];
    const formData = new FormData();
    formData.append("file", selectedFile);
    setFile(selectedFile);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = e => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(selectedFile);
    }

    if (crop == "soyabean") {
      try {
        setLoading(true)
        formData.append("model", model);
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/get/soyabean/seed/prediction`,
          formData,
          {
            "Content-Type": "multipart/form-data"
          }
        );
        if (response.data.status == "success") {
          setLoading(false)
          setPredictDisease(response.data);
        }
      } catch (error) {
        setLoading(false)
        console.error("Error uploading file:", error);
      }
    } else {
    }
  };
  return (
    <Container
      fluid
      className="main-content-container px-4 py-4"
      style={{ backgroundColor: "white" }}
    >
      <Grid container>
        <Grid item xs={4}>
          <Grid item xs={12} style={{ marginBottom: "0.5rem" }}>
            <Typography
              style={{
                color: "rgba(0, 0, 0, 0.80)",
                fontSize: "1.25rem",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal"
              }}
            >
              Crop Type
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Select
              labelId="asset-select-label"
              id="asset-select"
              name="cropType"
              value={crop}
              onChange={e => handleInputChange(e)}
              style={{ width: "20rem", height: "4.5rem" }}
            >
              {crops.map(crop => {
                return (
                  <MenuItem key={crop.id} value={crop.name}>
                    {crop.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid item xs={12} style={{ marginBottom: "0.5rem" }}>
            <Typography
              style={{
                color: "rgba(0, 0, 0, 0.80)",
                fontSize: "1.25rem",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal"
              }}
            >
              Model type
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {crop == "soyabean" && (
              <SoyabeanSeedDetection
                predictDisease={predictDisease}
                imagePreview={imagePreview}
                handleChange={handleChange}
                model={model}
                setModel={setModel}
              />
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ marginBottom: "1.25rem", marginTop: "2rem" }}
        >
          <Typography
            style={{
              color: "rgba(0, 0, 0, 0.80)",
              fontSize: "1.25rem",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal"
            }}
          >
            Upload an image of {crop} Seed to predict.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            component="label"
            style={{
              display: "flex",
              width: "8.75rem",
              padding: "1rem 1.5rem",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.25rem",
              borderRadius: "0.5rem",
              background: "#006BE0"
            }}
          >
           { loading ? <CircularProgress size={27} sx={{ color: 'white'}}/> : "Upload"}
            
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={handleChange}
            />
          </Button>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "1rem" }}>
          {imagePreview && (
            <img
              src={imagePreview}
              alt="Selected"
              style={{
                width: '18.75rem',
                height: '12.75rem',
                flexShrink: 0,
                borderRadius: '1.25rem',
                backgroundPosition: "cover"
              }}
            />

          )}
        </Grid>
        {predictDisease && (
          <>
            <Grid
              item
              xs={12}
              style={{
                marginTop: "2.5rem",
                alignItems: "center",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Typography
                style={{
                  color: "rgba(0, 0, 0, 0.80)",
                  fontSize: "1.5rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal"
                }}
              >
                Prediction:
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Typography
                style={{
                  color: "#FFFFFF",
                  fontFamily: "Inter",
                  fontSize: "1.5rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  padding: "0.75rem 1.5rem",
                  borderRadius: "0.75rem",
                  border: "4px solid #F58A20",
                  background: "#0E2748"
                }}
              >
                {predictDisease.predicted_class} with{" "}
                {predictDisease.confidence} confidence
              </Typography>
            </Grid>
          </>
        )}
        {predictDisease && (
          <Grid item xs={12}>
            <Plot
              style={{ width: "100%", height: "80vh" }}
              data={[
                {
                  type: "bar",
                  x: Object.values(predictDisease.class_labels),
                  y: predictDisease.predictions[0].map(p => p)
                }
              ]}
              layout={{
                title: "",
                //   title: "Prediction Confidence Scores",
                xaxis: {
                  title: "Seed Stage",
                  tickangle: -45
                },
                yaxis: {
                  title: "Confidence Score",
                  range: [0, 1]
                },
                margin: {
                  t: 100,
                  b: 200
                }
              }}
            />
          </Grid>
        )}
      </Grid>
      {/* <Row noGutters style={{ fontSize: '20px' }} className="page-header py-1">
                <PageTitle sm="4" title={`${crop} Seed Detection`} subtitle="" className="text-sm-left" />
            </Row>
            <Row noGutters className="py-3" style={{ fontSize: '13px' }}>Upload an image of a {crop} Seed to predict.</Row> */}
    </Container>
  );
};

export default SeedDetection;
