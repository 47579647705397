import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import { Store } from "../../../flux";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props)

    // this.state = {
    //   navItems: Store.getSidebarItems(),
    //   activeTab: props.items[0].to
    // };
    const navItems = Store.getSidebarItems();

    this.state = {
      navItems,
      activeTab: navItems.length > 0 ? navItems[0].to : "", // Default to the first item's `to` value
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  // onChange() {
  //   this.setState({
  //     ...this.state,
  //     navItems: Store.getSidebarItems()
  //   });
  // }

  onChange() {
    const navItems = Store.getSidebarItems();
    this.setState((prevState) => ({
      ...prevState,
      navItems,
    }));
  }

  // onChange() {
  //   const navItems = Store.getSidebarItems();
  //   this.setState((prevState) => ({
  //     navItems,
  //     activeTab: prevState.activeTab || (navItems.length > 0 ? navItems[0].to : ""),
  //   }));
  // }
  

  setActiveTab = (tab) => {
    console.log("Setting activeTab to:", tab);
    this.setState({ activeTab: tab }, () => {
      console.log("Updated activeTab:", this.state.activeTab);
    });
  };

  // setActiveTab (tab)  {
  //   if (this.state.activeTab !== tab) {
  //     console.log("setActiveTab called with:", tab);
  //     this.setState({ activeTab: tab }, () => {
  //       console.log("Updated activeTab:", this.state.activeTab);
  //     });
  //   } else {
  //     console.log("Tab is already active:", tab);
  //   }
  // };
  
  render() {
    const { navItems: items } = this.state;
    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10vh'}}>
        <img src="/Agrim.png" style={{ width: '50%', height: '6vh'}}/>
        </div>
        <Nav className="nav--no-borders flex-column">
          {items.map((item, idx) => (
            <SidebarNavItem key={idx} item={item} activeTab={this.state.activeTab} setActiveTab={this.setActiveTab}/>
          ))}
        </Nav>
      </div>
    )
  }
}

export default SidebarNavItems;
