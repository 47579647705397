import React, { Component } from 'react';
import axios from 'axios';
import { Button, Grid, Slider, Typography } from '@mui/material';

class RecommendationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      N: 3,
      P: 4,
      K: 9,
      temperature: 35.0,
      humidity: 13.5,
      ph: 11.27,
      rainfall: 50,
      prediction: null,
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/ai/recommend_crop`,
        this.state
      );

      this.setState({ prediction: response.data.prediction });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { N, P, K, temperature, humidity, ph, rainfall, prediction } =
      this.state;

    return ( 
      <>
      {/* <div style={styles.container}>
        <form onSubmit={this.handleSubmit} style={styles.form}>
          <label >
            Nitrogen(N):
            <input
              type="number"
              name="N"
              value={N}
              onChange={this.handleInputChange}
              style={{ marginLeft: 4}}
            />
          </label>
          <br />
          <label>
            Phosphorus(P):
            <input
              type="number"
              name="P"
              value={P}
              onChange={this.handleInputChange}
              style={{ marginLeft: 4}}

            />
          </label>
          <br />
          <label>
            Potassium(K):
            <input
              type="number"
              name="K"
              value={K}
              onChange={this.handleInputChange}
              style={{ marginLeft: 4}}

            />
          </label>
          <br />
          <label>
            Temperature:
            <input
              type="number"
              name="temperature"
              value={temperature}
              onChange={this.handleInputChange}
              style={{ marginLeft: 4}}

            />
          </label>
          <br />
          <label>
            Humidity:
            <input
              type="number"
              name="humidity"
              value={humidity}
              onChange={this.handleInputChange}
              style={{ marginLeft: 4}}

            />
          </label>
          <br />
          <label>
            pH:
            <input
              type="number"
              name="ph"
              value={ph}
              onChange={this.handleInputChange}
              style={{ marginLeft: 4}}

            />
          </label>
          <br />
          <label>
            Rainfall:
            <input
              type="number"
              name="rainfall"
              value={rainfall}
              onChange={this.handleInputChange}
              style={{ marginLeft: 4}}

            />
          </label>
          <br />
          <button type="submit">Submit</button>
        </form>
        {prediction && (
          <div style={styles.recommendation}>
            <h6>Recommendation:</h6>
            <p>{prediction}</p>
          </div>
        )}
      </div> */}
      <Grid container >
                <Grid item xs={12} >
                  <Grid item style={{ marginTop: '3rem', marginBottom: '2.25rem'}}>
        
                  <Typography
                    style={{
                      color: "rgba(0, 0, 0, 0.80)",
                      fontFeatureSettings: "'ss01' on, 'cv01' on, 'cv11' on",
                      fontFamily: "Inter, sans-serif",
                      fontSize: "1.5rem",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "1.125rem"
                    }}
                  >
                    Crop Recommendation
                  </Typography>
                  </Grid>
        
                </Grid>
      <Grid
          item
          xs={12}
          md={6}
          lg={3.87}
          xl={3.87}
          style={{
            borderRadius: "0.75rem",
            marginBottom: '1.75rem',
            marginRight: '1rem',
            border: "1px solid rgba(245, 138, 32, 0.60)",
            background: "#FFF",
            padding: "1rem",
            boxShadow: `
    1px 1px 2px 0px rgba(1, 1, 1, 0.3) inset,
    -1px -1px 2px 0px rgba(239, 239, 239, 0.50) inset,
    -4px 4px 8px 0px rgba(239, 239, 239, 0.20),
    4px -4px 8px 0px rgba(239, 239, 239, 0.20),
    -4px -4px 8px 0px rgba(255, 255, 255, 0.90),
    4px 4px 10px 0px rgba(239, 239, 239, 0.90)
  `
          }}
        >
          <Grid container>
            <Grid item xs={12} style={{ paddingBottom: "1.25rem" }}>
              <Typography
                variant="h8"
                style={{
                  color: "#181818",
                  fontSize: "1.25rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal"
                }}
              >
                Nitrogen ( mg/kg )
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Slider
                marks={[
                  {
                    value: 0,
                    label: "0"
                  },
                  {
                    value: N,
                    label: `${N}`, // Current value label
                  },
                  {
                    value: 100,
                    label: "100"
                  }
                ]}
                step={1}
                value={N}
                name="N"
                valueLabelDisplay="off"
                min={0}
                max={100}
                onChange={this.handleInputChange}
                sx={{
                  "& .MuiSlider-thumb": {
                    height: "1.5rem", // Thumb height
                    width: "1.5rem", // Thumb width
                    backgroundImage: "url(/thumb.svg)", // Custom image URL
                    backgroundPosition: "center", // Center the image in the thumb
                    "&:hover, &.Mui-focusVisible": {
                      boxShadow: "none" // Remove the glow effect on hover/focus
                    }
                  },
                  "& .MuiSlider-rail": {
                    height: "1.5rem", // Rail height
                    backgroundColor: "grey", // Non-slided portion color (rail)
                    borderRadius: "0.75rem" // Rounded rail
                  },
                  "& .MuiSlider-track": {
                    height: "1.5rem", // Track height
                    borderRadius: "0.75rem", // Rounded track
                    backgroundColor: "#0E2748" // Active portion color (track)
                  },
                  // "& .MuiSlider-markLabel[data-index='0']": {
                  //   transform: "translateY(6px) translateX(0px)" // Customize min label position
                  // },
                  // "& .MuiSlider-markLabel[data-index='1']": {
                  //   transform: "translateY(6px) translateX(-35px)" // Customize max label position
                  // }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      <Grid
          item
          xs={12}
          md={6}
          lg={3.87}
          xl={3.87}
          style={{
            borderRadius: "0.75rem",
            marginBottom: '1.75rem',
            marginRight: '1rem',
            border: "1px solid rgba(245, 138, 32, 0.60)",
            background: "#FFF",
            padding: "1rem",
            boxShadow: `
    1px 1px 2px 0px rgba(1, 1, 1, 0.3) inset,
    -1px -1px 2px 0px rgba(239, 239, 239, 0.50) inset,
    -4px 4px 8px 0px rgba(239, 239, 239, 0.20),
    4px -4px 8px 0px rgba(239, 239, 239, 0.20),
    -4px -4px 8px 0px rgba(255, 255, 255, 0.90),
    4px 4px 10px 0px rgba(239, 239, 239, 0.90)
  `
          }}
        >
          <Grid container>
            <Grid item xs={12} style={{ paddingBottom: "1.25rem" }}>
              <Typography
                variant="h8"
                style={{
                  color: "#181818",
                  fontSize: "1.25rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal"
                }}
              >
                Phosphorus ( mg/kg )
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Slider
                marks={[
                  {
                    value: 0,
                    label: "0"
                  },
                  {
                    value: P,
                    label: `${P}`, // Current value label
                  },
                  {
                    value: 100,
                    label: "100"
                  }
                ]}
                step={1}
                value={P}
                name="P"
                valueLabelDisplay="off"
                min={0}
                max={100}
                onChange={this.handleInputChange}
                sx={{
                  "& .MuiSlider-thumb": {
                    height: "1.5rem", // Thumb height
                    width: "1.5rem", // Thumb width
                    backgroundImage: "url(/thumb.svg)", // Custom image URL
                    backgroundPosition: "center", // Center the image in the thumb
                    "&:hover, &.Mui-focusVisible": {
                      boxShadow: "none" // Remove the glow effect on hover/focus
                    }
                  },
                  "& .MuiSlider-rail": {
                    height: "1.5rem", // Rail height
                    backgroundColor: "grey", // Non-slided portion color (rail)
                    borderRadius: "0.75rem" // Rounded rail
                  },
                  "& .MuiSlider-track": {
                    height: "1.5rem", // Track height
                    borderRadius: "0.75rem", // Rounded track
                    backgroundColor: "#0E2748" // Active portion color (track)
                  },
                  // "& .MuiSlider-markLabel[data-index='0']": {
                  //   transform: "translateY(6px) translateX(0px)" // Customize min label position
                  // },
                  // "& .MuiSlider-markLabel[data-index='1']": {
                  //   transform: "translateY(6px) translateX(-35px)" // Customize max label position
                  // }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      <Grid
          item
          xs={12}
          md={6}
          lg={3.87}
          xl={3.87}
          style={{
            borderRadius: "0.75rem",
            marginBottom: '1.75rem',
            border: "1px solid rgba(245, 138, 32, 0.60)",
            background: "#FFF",
            padding: "1rem",
            boxShadow: `
    1px 1px 2px 0px rgba(1, 1, 1, 0.3) inset,
    -1px -1px 2px 0px rgba(239, 239, 239, 0.50) inset,
    -4px 4px 8px 0px rgba(239, 239, 239, 0.20),
    4px -4px 8px 0px rgba(239, 239, 239, 0.20),
    -4px -4px 8px 0px rgba(255, 255, 255, 0.90),
    4px 4px 10px 0px rgba(239, 239, 239, 0.90)
  `
          }}
        >
          <Grid container>
            <Grid item xs={12} style={{ paddingBottom: "1.25rem" }}>
              <Typography
                variant="h8"
                style={{
                  color: "#181818",
                  fontSize: "1.25rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal"
                }}
              >
                Potassium ( mg/kg )
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Slider
                marks={[
                  {
                    value: 0,
                    label: "0"
                  },
                  {
                    value: K,
                    label: `${K}`, // Current value label
                  },
                  {
                    value: 200,
                    label: "200"
                  }
                ]}
                step={1}
                value={K}
                name="K"
                valueLabelDisplay="off"
                min={0}
                max={200}
                onChange={this.handleInputChange}
                sx={{
                  "& .MuiSlider-thumb": {
                    height: "1.5rem", // Thumb height
                    width: "1.5rem", // Thumb width
                    backgroundImage: "url(/thumb.svg)", // Custom image URL
                    backgroundPosition: "center", // Center the image in the thumb
                    "&:hover, &.Mui-focusVisible": {
                      boxShadow: "none" // Remove the glow effect on hover/focus
                    }
                  },
                  "& .MuiSlider-rail": {
                    height: "1.5rem", // Rail height
                    backgroundColor: "grey", // Non-slided portion color (rail)
                    borderRadius: "0.75rem" // Rounded rail
                  },
                  "& .MuiSlider-track": {
                    height: "1.5rem", // Track height
                    borderRadius: "0.75rem", // Rounded track
                    backgroundColor: "#0E2748" // Active portion color (track)
                  },
                  // "& .MuiSlider-markLabel[data-index='0']": {
                  //   transform: "translateY(6px) translateX(0px)" // Customize min label position
                  // },
                  // "& .MuiSlider-markLabel[data-index='1']": {
                  //   transform: "translateY(6px) translateX(-35px)" // Customize max label position
                  // }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      <Grid
          item
          xs={12}
          md={6}
          lg={3.87}
          xl={3.87}
          style={{
            borderRadius: "0.75rem",
            marginRight: '1rem',
            marginBottom: '1.75rem',
            border: "1px solid rgba(245, 138, 32, 0.60)",
            background: "#FFF",
            padding: "1rem",
            boxShadow: `
    1px 1px 2px 0px rgba(1, 1, 1, 0.3) inset,
    -1px -1px 2px 0px rgba(239, 239, 239, 0.50) inset,
    -4px 4px 8px 0px rgba(239, 239, 239, 0.20),
    4px -4px 8px 0px rgba(239, 239, 239, 0.20),
    -4px -4px 8px 0px rgba(255, 255, 255, 0.90),
    4px 4px 10px 0px rgba(239, 239, 239, 0.90)
  `
          }}
        >
          <Grid container>
            <Grid item xs={12} style={{ paddingBottom: "1.25rem" }}>
              <Typography
                variant="h8"
                style={{
                  color: "#181818",
                  fontSize: "1.25rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal"
                }}
              >
                Temperature ( °C )
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Slider
                marks={[
                  {
                    value: -10,
                    label: "-10"
                  },
                  {
                    value: temperature,
                    label: `${temperature}`, // Current value label
                  },
                  {
                    value: 55,
                    label: "55"
                  }
                ]}
                step={1}
                value={temperature}
                name="temperature"
                valueLabelDisplay="off"
                min={-10}
                max={55}
                onChange={this.handleInputChange}
                sx={{
                  "& .MuiSlider-thumb": {
                    height: "1.5rem", // Thumb height
                    width: "1.5rem", // Thumb width
                    backgroundImage: "url(/thumb.svg)", // Custom image URL
                    backgroundPosition: "center", // Center the image in the thumb
                    "&:hover, &.Mui-focusVisible": {
                      boxShadow: "none" // Remove the glow effect on hover/focus
                    }
                  },
                  "& .MuiSlider-rail": {
                    height: "1.5rem", // Rail height
                    backgroundColor: "grey", // Non-slided portion color (rail)
                    borderRadius: "0.75rem" // Rounded rail
                  },
                  "& .MuiSlider-track": {
                    height: "1.5rem", // Track height
                    borderRadius: "0.75rem", // Rounded track
                    backgroundColor: "#0E2748" // Active portion color (track)
                  },
                  // "& .MuiSlider-markLabel[data-index='0']": {
                  //   transform: "translateY(6px) translateX(0px)" // Customize min label position
                  // },
                  // "& .MuiSlider-markLabel[data-index='1']": {
                  //   transform: "translateY(6px) translateX(-35px)" // Customize max label position
                  // }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      <Grid
          item
          xs={12}
          md={6}
          lg={3.87}
          xl={3.87}
          style={{
            borderRadius: "0.75rem",
            marginRight: '1rem',
            marginBottom: '1.75rem',
            border: "1px solid rgba(245, 138, 32, 0.60)",
            background: "#FFF",
            padding: "1rem",
            boxShadow: `
    1px 1px 2px 0px rgba(1, 1, 1, 0.3) inset,
    -1px -1px 2px 0px rgba(239, 239, 239, 0.50) inset,
    -4px 4px 8px 0px rgba(239, 239, 239, 0.20),
    4px -4px 8px 0px rgba(239, 239, 239, 0.20),
    -4px -4px 8px 0px rgba(255, 255, 255, 0.90),
    4px 4px 10px 0px rgba(239, 239, 239, 0.90)
  `
          }}
        >
          <Grid container>
            <Grid item xs={12} style={{ paddingBottom: "1.25rem" }}>
              <Typography
                variant="h8"
                style={{
                  color: "#181818",
                  fontSize: "1.25rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal"
                }}
              >
                Humidity ( %RH )
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Slider
                marks={[
                  {
                    value: 0,
                    label: "0"
                  },
                  {
                    value: humidity,
                    label: `${humidity}`, // Current value label
                  },
                  {
                    value: 200,
                    label: "200"
                  }
                ]}
                step={1}
                value={humidity}
                name="humidity"
                valueLabelDisplay="off"
                min={0}
                max={200}
                onChange={this.handleInputChange}
                sx={{
                  "& .MuiSlider-thumb": {
                    height: "1.5rem", // Thumb height
                    width: "1.5rem", // Thumb width
                    backgroundImage: "url(/thumb.svg)", // Custom image URL
                    backgroundPosition: "center", // Center the image in the thumb
                    "&:hover, &.Mui-focusVisible": {
                      boxShadow: "none" // Remove the glow effect on hover/focus
                    }
                  },
                  "& .MuiSlider-rail": {
                    height: "1.5rem", // Rail height
                    backgroundColor: "grey", // Non-slided portion color (rail)
                    borderRadius: "0.75rem" // Rounded rail
                  },
                  "& .MuiSlider-track": {
                    height: "1.5rem", // Track height
                    borderRadius: "0.75rem", // Rounded track
                    backgroundColor: "#0E2748" // Active portion color (track)
                  },
                  // "& .MuiSlider-markLabel[data-index='0']": {
                  //   transform: "translateY(6px) translateX(0px)" // Customize min label position
                  // },
                  // "& .MuiSlider-markLabel[data-index='1']": {
                  //   transform: "translateY(6px) translateX(-35px)" // Customize max label position
                  // }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      <Grid
          item
          xs={12}
          md={6}
          lg={3.87}
          xl={3.87}
          style={{
            borderRadius: "0.75rem",
            marginBottom: '1.75rem',
            border: "1px solid rgba(245, 138, 32, 0.60)",
            background: "#FFF",
            padding: "1rem",
            boxShadow: `
    1px 1px 2px 0px rgba(1, 1, 1, 0.3) inset,
    -1px -1px 2px 0px rgba(239, 239, 239, 0.50) inset,
    -4px 4px 8px 0px rgba(239, 239, 239, 0.20),
    4px -4px 8px 0px rgba(239, 239, 239, 0.20),
    -4px -4px 8px 0px rgba(255, 255, 255, 0.90),
    4px 4px 10px 0px rgba(239, 239, 239, 0.90)
  `
          }}
        >
          <Grid container>
            <Grid item xs={12} style={{ paddingBottom: "1.25rem" }}>
              <Typography
                variant="h8"
                style={{
                  color: "#181818",
                  fontSize: "1.25rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal"
                }}
              >
                pH
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Slider
                marks={[
                  {
                    value: 0,
                    label: "0"
                  },
                  {
                    value: ph,
                    label: `${ph}`, // Current value label
                  },
                  {
                    value: 14,
                    label: "14"
                  }
                ]}
                step={0.1}
                value={ph}
                name="ph"
                valueLabelDisplay="off"
                min={0}
                max={14}
                onChange={this.handleInputChange}
                sx={{
                  "& .MuiSlider-thumb": {
                    height: "1.5rem", // Thumb height
                    width: "1.5rem", // Thumb width
                    backgroundImage: "url(/thumb.svg)", // Custom image URL
                    backgroundPosition: "center", // Center the image in the thumb
                    "&:hover, &.Mui-focusVisible": {
                      boxShadow: "none" // Remove the glow effect on hover/focus
                    }
                  },
                  "& .MuiSlider-rail": {
                    height: "1.5rem", // Rail height
                    backgroundColor: "grey", // Non-slided portion color (rail)
                    borderRadius: "0.75rem" // Rounded rail
                  },
                  "& .MuiSlider-track": {
                    height: "1.5rem", // Track height
                    borderRadius: "0.75rem", // Rounded track
                    backgroundColor: "#0E2748" // Active portion color (track)
                  },
                  // "& .MuiSlider-markLabel[data-index='0']": {
                  //   transform: "translateY(6px) translateX(0px)" // Customize min label position
                  // },
                  // "& .MuiSlider-markLabel[data-index='1']": {
                  //   transform: "translateY(6px) translateX(-35px)" // Customize max label position
                  // }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      <Grid
          item
          xs={12}
          md={6}
          lg={3.87}
          xl={3.87}
          
          style={{
            borderRadius: "0.75rem",
            marginRight: '1rem',
            border: "1px solid rgba(245, 138, 32, 0.60)",
            background: "#FFF",
            padding: "1rem",
            boxShadow: `
    1px 1px 2px 0px rgba(1, 1, 1, 0.3) inset,
    -1px -1px 2px 0px rgba(239, 239, 239, 0.50) inset,
    -4px 4px 8px 0px rgba(239, 239, 239, 0.20),
    4px -4px 8px 0px rgba(239, 239, 239, 0.20),
    -4px -4px 8px 0px rgba(255, 255, 255, 0.90),
    4px 4px 10px 0px rgba(239, 239, 239, 0.90)
  `
          }}
        >
          <Grid container>
            <Grid item xs={12} style={{ paddingBottom: "1.25rem" }}>
              <Typography
                variant="h8"
                style={{
                  color: "#181818",
                  fontSize: "1.25rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal"
                }}
              >
                Rainfall
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Slider
                marks={[
                  {
                    value: 0,
                    label: "0"
                  },
                  {
                    value: rainfall,
                    label: `${rainfall}`, // Current value label
                  },
                  {
                    value: 200,
                    label: "200"
                  }
                ]}
                step={1}
                value={rainfall}
                name="rainfall"
                valueLabelDisplay="off"
                min={0}
                max={200}
                onChange={this.handleInputChange}
                sx={{
                  "& .MuiSlider-thumb": {
                    height: "1.5rem", // Thumb height
                    width: "1.5rem", // Thumb width
                    backgroundImage: "url(/thumb.svg)", // Custom image URL
                    backgroundPosition: "center", // Center the image in the thumb
                    "&:hover, &.Mui-focusVisible": {
                      boxShadow: "none" // Remove the glow effect on hover/focus
                    }
                  },
                  "& .MuiSlider-rail": {
                    height: "1.5rem", // Rail height
                    backgroundColor: "grey", // Non-slided portion color (rail)
                    borderRadius: "0.75rem" // Rounded rail
                  },
                  "& .MuiSlider-track": {
                    height: "1.5rem", // Track height
                    borderRadius: "0.75rem", // Rounded track
                    backgroundColor: "#0E2748" // Active portion color (track)
                  },
                  // "& .MuiSlider-markLabel[data-index='0']": {
                  //   transform: "translateY(6px) translateX(0px)" // Customize min label position
                  // },
                  // "& .MuiSlider-markLabel[data-index='1']": {
                  //   transform: "translateY(6px) translateX(-35px)" // Customize max label position
                  // }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "2rem" }}>

        <Grid item xs={12} style={{ marginTop: "2.13rem" }}>
          <Button
            variant="contained"
            onClick={this.handleSubmit}
            style={{
              display: "flex",
              width: "8.75rem",
              padding: "1rem 1.5rem",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.25rem",
              borderRadius: "0.5rem",
              background: "#006BE0"
            }}
          >
            Predict
          </Button>
        </Grid>
        {prediction && (
          <>
            <Grid item xs={12} style={{ marginTop: "3.5rem", alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
              <Typography
                style={{
                  color: "rgba(0, 0, 0, 0.80)",
                  fontSize: "1.5rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal"
                }}
              >
                Recommendation:
              </Typography>
            </Grid>
            <Grid item xs={12}  style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
              <Typography
                style={{
                  color: "#FFFFFF",
                  fontFamily: "Inter",
                  fontSize: "1.5rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                   padding: '0.75rem 1.5rem', borderRadius: '0.75rem', border: '4px solid #F58A20', background: '#0E2748' 
                }}
              >
                {prediction}
              </Typography>
            </Grid>
          </>
        )}
        {/* {predicted && (
          <div style={{ fontSize: 35, fontWeight: "bolder" }}>
            N-P-K : {predicted}
          </div>
        )} */}
      </Grid>


      </>
    );
  }
}

const styles = {
  container: {
    maxWidth: '400px',
    margin: 'auto',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  recommendation: {
    marginTop: '20px',
    padding: '10px',
    backgroundColor: '#f2f2f2',
    borderRadius: '4px',
  },
};

export default RecommendationForm;
