import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink as RouteNavLink } from "react-router-dom";
import { NavItem, NavLink } from "shards-react";

const SidebarNavItem = ({ item, activeTab, setActiveTab }) => {
  
  const isActive = activeTab === item.to;
  
  return ( 
  <>
    {/* Scoped styles for this component */}
    <style>
      {`
      .nav-link {
        color: #000 ; 
      }

      .nav-link:hover {
        color: #2EAC4E !important ;
      }

      .nav-link.active {
        color: #2EAC4E !important;
      }
      `}
    </style>
    <NavItem>
      <NavLink
        tag={RouteNavLink}
        to={item.to}
        className={`nav-link ${isActive ? "active" : ""}`}
        onClick={() => setActiveTab(item.to)}
      >
        {item.htmlBefore && (
          <div
            className="d-inline-block item-icon-wrapper"
            dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
          />
        )}
        {item.title && <span>{item.title}</span>}
        {item.htmlAfter && (
          <div
            className="d-inline-block item-icon-wrapper"
            dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
          />
        )}
      </NavLink>
    </NavItem>
    </>
  );
};

SidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object
};

export default SidebarNavItem;
