export default function() {
  return [

    // {
    //   title: "Analytics",
    //   // htmlBefore: '<i class="material-icons">vertical_split</i>',
    //   to: "/analysis",
    // },
    {
      title: "Soil Health Monitoring",
      to: "/",
      // htmlBefore: '<i class="material-icons">edit</i>',
    },
    {
      title: "Crop Recommendation",
      // htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/crop-recommendation",
    },

    {
      title: "Soil Data Analysis",
      // htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/agri/soil/data/analysis",
    },
    {
      title: "Fertilizer Recommendation",
      // htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/fertilizer-recommendation",
    },
    {
      title: "Soil Type Detector",
      // htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/soil/type/detector",
    },
    {
      title: "Weather Prediction",
      // htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/weather-prediction",
    }, 
    {
      title: "Seed Quality Detection",
      // htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/seeds/detection",
    },
    {
      title: "Soyabean Plant Heath Detection",
      // htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/disease-detection",
    },
    {
      title: "Yield Prediction",
      // htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/yield-prediction",
    },
    // {
    //   title: "Visualisation",
    //   // htmlBefore: '<i class="material-icons">view_module</i>',
    //   to: "/visualisation",
    // },
    {
      title: "SoyaBean Statistics",
      // htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/soya",
    },
    {
      title: "Management Practices",
      // htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/management_practices",
    },
    {
      title: "Reports",
      // htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/reports",
    },
    // {
    //   title: "Soil Test Analyzer",
    //   // htmlBefore: '<i class="material-icons">view_module</i>',
    //   to: "/soil/test/analyzer",
    // },
    {
      title: "Nutrient Calculator",
      // htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/soil/nutrient/calculator",
    },



    // {
    //   title: "Tables",
    //   htmlBefore: '<i class="material-icons">table_chart</i>',
    //   to: "/tables",
    // },
    // {
    //   title: "User Profile",
    //   htmlBefore: '<i class="material-icons">person</i>',
    //   to: "/user-profile-lite",
    // },
    // {
    //   title: "Errors",
    //   htmlBefore: '<i class="material-icons">error</i>',
    //   to: "/errors",
    // }
  ];
}
