import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import axios from "axios";
import Plot from "react-plotly.js";
const management_practices = () => {
  const [crop, setSelectedCrop] = useState("soyabean");
  const [stag, setSelectedStage] = useState("Initial seedling stage");
  const [stagData, setSelectedStageData] = useState({});
  const [data, setSelectedData] = useState();
  const [fertigatedata, SetFertigatedData] = useState();
  const [stage, setStages] = useState({});
  const [dataAgri, setData] = useState([]);
  const [val, setVal] = useState({
    moisture: 0.32
  });
  let crops = [
    {
      id: 1,
      name: "soyabean"
    }
  ];

  const handleInputChange = e => {
    setSelectedCrop(e.target.value);
  };
  const handleChange = e => {
    setSelectedStage(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/agri/${crop}/analytics`
      );
      if (response.data.status == "success") {
        setSelectedData(response.data);
      }
    };

    fetchData();
  }, [crop]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/agri/${crop}/growth/stages`
      );
      if (response.data.status == "success") {
        setStages(response.data.stages);
      }
    };

    fetchData();
  }, [crop]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/agri/latest`
      );
      if (response.data.status == "success") {
        setData(response.data);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/agri/${crop}/growth/per/stages`,
        {
          stages: stag
        }
      );
      if (response.data.status == "success") {
        setSelectedStageData(response.data.stages);
      }
    };
    fetchData();
  }, [stag]);

  const is_between_10_to_19 = () => {
    const now = new Date();
    const isWithinRange = now.getHours() >= 10 && now.getHours() < 19;
    return isWithinRange;
  };

  if (!data) {
    return <>No Data</>;
  }
  let sowing_times = data.sowing_times;
  let spacing = data.spacing;
  let zones = data.zones;
  let stages = data.stages;
  let etValuesNumeric = data.et_values;
  let cropStages = data.crop_stages;
  let minTemp = data.min_temp;
  let maxTemp = data.max_temp;
  let growthStages = data.growth_stages;
  let waterUse = data.water_use;

  const days = data.days;
  const nutrients = data.nutrients;
  const data6 = {
    "Days (interval)": days,
    "Nitrogen (kg/ha)": Object.values(Object.values(data.dfFertilizerDose)[1]),
    "Phosphorus (kg/ha)": Object.values(
      Object.values(data.dfFertilizerDose)[2]
    ),
    "Potassium (kg/ha)": Object.values(Object.values(data.dfFertilizerDose)[3])
  };

  const keys = Object.keys(stagData);
  if (keys.length < 5) {
    console.error("stagData does not have enough keys");
    return <div>Invalid data</div>;
  }

  // Safely access the values
  const nitrogen = stagData[keys[2]]
    ? Object.values(stagData[keys[2]])[0]
    : null;
  const phosphorus = stagData[keys[3]]
    ? Object.values(stagData[keys[3]])[0]
    : null;
  const potassium = stagData[keys[4]]
    ? Object.values(stagData[keys[4]])[0]
    : null;

  const predict_fertigation = () => {
    let actions = [];
    if (dataAgri.data[0]["nitrogen"] < thresholds[stag]["nitrogen_low"]) {
      actions.push("Nitrogen is low. Switching on Nitrogen solenoid valve.");
    } else if (
      dataAgri.data[0]["nitrogen"] > thresholds[stag]["nitrogen_high"]
    ) {
      actions.push("Nitrogen is high. Switching off Nitrogen solenoid valve.");
    }
    if (dataAgri.data[0]["phosphorus"] < thresholds[stag]["phosphorus_low"]) {
      actions.push(
        "phosphorus is low. Switching on phosphorus solenoid valve."
      );
    } else if (
      dataAgri.data[0]["phosphorus"] > thresholds[stag]["phosphorus_high"]
    ) {
      actions.push(
        "phosphorus is high. Switching off phosphorus solenoid valve."
      );
    }
    if (dataAgri.data[0]["potassium"] < thresholds[stag]["potassium_low"]) {
      actions.push("potassium is low. Switching on potassium solenoid valve.");
    } else if (
      dataAgri.data[0]["potassium"] > thresholds[stag]["potassium_high"]
    ) {
      actions.push(
        "potassium is high. Switching off potassium solenoid valve."
      );
    }
    if (val.moisture < thresholds[stag]["soil_moisture_low"]) {
      actions.push("Soil moisture is low. Switching on water solenoid valve.");
    } else if (val.moisture > thresholds[stag]["soil_moisture_high"]) {
      actions.push(
        "Soil moisture is high. Switching off water solenoid valve."
      );
    }

    return actions;
  };

  // Define stage-specific thresholds for nutrients and soil moisture
  const thresholds = {
    "Initial seedling stage": {
      nitrogen_low: 18,
      nitrogen_high: 19,
      phosphorus_low: 18,
      phosphorus_high: 19,
      potassium_low: 18,
      potassium_high: 19,
      soil_moisture_low: 0.05,
      soil_moisture_high: 0.1
    },
    "Crop development stage": {
      nitrogen_low: 11,
      nitrogen_high: 12,
      phosphorus_low: 60,
      phosphorus_high: 61,
      potassium_low: 0,
      potassium_high: 1,
      soil_moisture_low: 0.1,
      soil_moisture_high: 0.2
    },
    "Mid Season growth stage": {
      nitrogen_low: 0,
      nitrogen_high: 1,
      phosphorus_low: 51,
      phosphorus_high: 52,
      potassium_low: 33,
      potassium_high: 34,
      soil_moisture_low: 0.2,
      soil_moisture_high: 0.3
    },
    "Late season growth stage": {
      nitrogen_low: 12,
      nitrogen_high: 13,
      phosphorus_low: 0,
      phosphorus_high: 1,
      potassium_low: 44,
      potassium_high: 45,
      soil_moisture_low: 0.05,
      soil_moisture_high: 0.2
    },
    "Maturity to Harvest": {
      nitrogen_low: 0,
      nitrogen_high: 1,
      phosphorus_low: 0,
      phosphorus_high: 1,
      potassium_low: 49,
      potassium_high: 50,
      soil_moisture_low: 0.05,
      soil_moisture_high: 0.2
    }
  };

  const handleFertigation = () => {
    const fertigated_data = predict_fertigation();
    console.log(fertigated_data);
    SetFertigatedData(fertigated_data);
  };

  // Check if all values are valid
  if (nitrogen === null || phosphorus === null || potassium === null) {
    console.error("One or more nutrient values are missing");
    return <div>Invalid data</div>;
  }

  //   const plots = nutrients.map((nutrient, i) => ({
  //     x: data6["Days (interval)"],
  //     y: data6[nutrient],
  //     type: 'bar',
  //     name: nutrient,
  //     marker: { color: 'skyblue' },
  //     xaxis: `x${i + 1}`,
  //     yaxis: `y${i + 1}`
  //   }));

  const layout = {
    title: "",
    grid: { rows: nutrients.length, columns: 1, pattern: "independent" },
    xaxis: { title: "Days after Planting" },
    yaxis: { title: "Amount (kg/ha)" },
    barmode: "group",
    height: 600 * nutrients.length,
    width: 1200,
    showlegend: true,
    legend: { orientation: "h", y: -0.1 },
    annotations: nutrients.map((nutrient, i) => ({
      text: `Amount of ${nutrient} Applied over Time`,
      xref: "x1",
      yref: `y${i + 1}`,
      x: 0.5,
      y: 65,
      xanchor: "center",
      yanchor: "bottom",
      showarrow: false,
      font: { size: 16 }
    }))
  };

  nutrients.forEach((nutrient, i) => {
    layout[`xaxis${i + 1}`] = {
      title: "Days after Planting",
      anchor: `y${i + 1}`,
      tickangle: -45
    };
    layout[`yaxis${i + 1}`] = {
      title: `${nutrient} Amount (kg/ha)`,
      anchor: `x${i + 1}`,
      gridcolor: "rgba(0, 0, 0, 0.2)",
      zeroline: false
    };
    layout[`annotations${i + 1}`] = [
      {
        text: `Amount of ${nutrient} Applied over Time`,
        xref: "paper",
        yref: "paper",
        x: 0.5,
        y: 1,
        xanchor: "center",
        yanchor: "bottom",
        showarrow: false,
        font: {
          size: 16
        }
      }
    ];
  });

  const plots1 = nutrients.map((nutrient, i) => ({
    x: data6["Days (interval)"],
    y: data6[nutrient],
    type: "scatter",
    mode: "lines+markers",
    name: nutrient,
    marker: { color: "blue" },
    xaxis: `x${i + 1}`,
    yaxis: `y${i + 1}`
  }));

  const bars = nutrients.map((nutrient, i) => ({
    x: data6["Days (interval)"],
    y: data6[nutrient],
    type: "bar",
    name: nutrient,
    marker: { color: "rgba(255, 144, 14, 0.5)" },
    xaxis: `x${i + 1}`,
    yaxis: `y${i + 1}`
  }));

  return (
    <>
      <Container
        fluid
        className="main-content-container px-4"
        style={{ backgroundColor: "white" }}
      >
        {/* <PageTitle sm="4" title="SoyaBean" subtitle="" className="text-sm-left" /> */}
        {/* <Col>
            <FormControl style={{ marginTop: "12px" }}>
              <InputLabel id="asset-select-label">Crop Type</InputLabel>

              <Select
                labelId="asset-select-label"
                id="asset-select"
                name="cropType"
                value={crop}
                onChange={e => handleInputChange(e)}
                label="Select category"
                style={{ width: 165 }}
              >
                {crops.map(crop => {
                  return (
                    <MenuItem key={crop.id} value={crop.name}>
                      {crop.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Col> */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <Grid item xs={12} style={{ marginBottom: "0.5rem", marginTop: "3rem" }}>
              <Typography
                style={{
                  color: "rgba(0, 0, 0, 0.80)",
                  fontSize: "1.25rem",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal"
                }}
              >
                Crop Type
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Select
                labelId="asset-select-label"
                id="asset-select"
                name="cropType"
                value={crop}
                onChange={e => handleInputChange(e)}
                style={{ width: "21.875rem" }}
              >
                {crops.map(crop => {
                  return (
                    <MenuItem key={crop.id} value={crop.name}>
                      {crop.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={5} >
            <Grid item xs={12} style={{ marginBottom: "0.5rem", marginTop: "3rem" }}>
              <Typography
                style={{
                  color: "rgba(0, 0, 0, 0.80)",
                  fontSize: "1.25rem",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal"
                }}
              >
                Crop Stages
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Select
                labelId="asset-select-label"
                id="asset-select"
                name="cropType"
                value={stag}
                style={{ width: "21.875rem" }}
                onChange={e => handleChange(e)}
              >
                {Object.values(stage).map(crop => {
                  return (
                    <MenuItem key={crop} value={crop}>
                      {crop}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} lg={5} xl={4} style={{ marginTop: "1.94rem" }}>
            <Box
              sx={{
                position: "relative",
                display: "inline-block",
                border: "1px solid #828282",
                borderRadius: "0.25rem",
                overflow: "hidden",
                width: "21.875rem"
              }}
            >
              <Table>
                <TableBody>
                  {Object.keys(stagData).map(
                    data =>
                      stag != Object.values(stagData[data])[0] && (
                        <TableRow>
                          <TableCell
                            style={{
                              padding: "0.625rem 0.5rem",
                              alignItems: "center",
                              backgroundColor: "#EBEBEB",
                              gap: "0.625rem",
                              borderRight: "1px solid #828282",
                              borderBottom: "1px solid #828282"
                            }}
                          >
                            {data} :
                          </TableCell>
                          <TableCell
                            style={{
                              padding: "0.625rem 0.5rem",
                              alignItems: "center",
                              gap: "0.625rem",
                              borderBottom: "1px solid #828282"
                            }}
                          >
                            {Object.values(stagData[data])[0]}
                          </TableCell>
                        </TableRow>
                      )
                  )}
                </TableBody>
              </Table>
            </Box>
          </Grid>
          {stagData && (
            <Grid
              item
              xs={12}
              md={6}
              lg={5}
              style={{
                marginTop: "1.94rem"
              }}
            >
              <Grid
                item
                style={{
                  borderRadius: "0.25rem",
                  border: "1px solid #828282",
                  height: "20.0625rem"
                }}
              >
                <Plot
                  data={[
                    {
                      x: ["Nitrogen", "Phosphorus", "Potassium"],
                      y: [nitrogen, phosphorus, potassium],
                      type: "bar",
                      marker: {
                        color: ["blue", "green", "orange"]
                      }
                    }
                  ]}
                  layout={{
                    title: `Nutrient Levels for ${stag}`,
                    xaxis: {
                      title: "Nutrients"
                    },
                    yaxis: {
                      title: "kg/ha"
                    },
                    width: 460,
                    height: 310
                  }}
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} md={6} lg={5}>
            <Grid
              container
              style={{
                borderRadius: "0.75rem",
                border: "1px solid rgba(245, 138, 32, 0.60)",
                background: "#FFF",
                marginTop: "4rem",
                padding: "1rem",
                boxShadow: `
                1px 1px 2px 0px rgba(255, 255, 255, 0.30) inset,
                -1px -1px 2px 0px rgba(239, 239, 239, 0.50) inset,
                -4px 4px 8px 0px rgba(239, 239, 239, 0.20),
                4px -4px 8px 0px rgba(239, 239, 239, 0.20),
                -4px -4px 8px 0px rgba(255, 255, 255, 0.90),
                4px 4px 10px 0px rgba(239, 239, 239, 0.90)
                `
              }}
            >
              <Grid item xs={12} style={{ paddingBottom: "1.25rem" }}>
                <Typography
                  variant="h8"
                  style={{
                    color: "#181818",
                    fontSize: "1.25rem",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal"
                  }}
                >
                  Select Current Soil Moisture (in inches/day):
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Slider
                  marks={[
                    {
                      value: 0.0,
                      label: "0.0"
                    },
                    {
                      value: val.moisture,
                      label: `${val.moisture}` // Current value label
                    },
                    {
                      value: 1.0,
                      label: "1.0"
                    }
                  ]}
                  step={0.01}
                  value={val.moisture}
                  name="moisture"
                  valueLabelDisplay="auto"
                  min={0.0}
                  max={1.0}
                  onChange={e => setVal({ [e.target.name]: e.target.value })}
                  sx={{
                    "& .MuiSlider-thumb": {
                      height: "1.5rem", // Thumb height
                      width: "1.5rem", // Thumb width
                      backgroundImage: "url(/thumb.svg)", // Custom image URL
                      backgroundPosition: "center", // Center the image in the thumb
                      "&:hover, &.Mui-focusVisible": {
                        boxShadow: "none" // Remove the glow effect on hover/focus
                      }
                    },
                    "& .MuiSlider-rail": {
                      height: "1.5rem", // Rail height
                      backgroundColor: "grey", // Non-slided portion color (rail)
                      borderRadius: "0.75rem" // Rounded rail
                    },
                    "& .MuiSlider-track": {
                      height: "1.5rem", // Track height
                      borderRadius: "0.75rem", // Rounded track
                      backgroundColor: "#0E2748" // Active portion color (track)
                    },
                    "& .MuiSlider-markLabel[data-index='0']": {
                      transform: "translateY(6px) translateX(0px)" // Customize min label position
                    },
                    "& .MuiSlider-markLabel[data-index='1']": {
                      transform: "translateY(6px) translateX(-15px)" // Customize max label position
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          {fertigatedata && fertigatedata.length && (
            <Grid item xs={12} md={6} lg={5}>
              <Grid
                container
                style={{
                  borderRadius: "0.75rem",
                  border: "1px solid rgba(0, 0, 0, 0.20)",
                  background: "#FFF",
                  marginTop: "4rem",
                  paddingTop: "1rem"
                }}
              >
                <ul>
                  {fertigatedata.map((data, index) => (
                    <li
                      key={index}
                      style={{
                        color: "#000",
                        fontFamily: "Inter, sans-serif",
                        fontSize: "1rem",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "normal"
                      }}
                    >
                      <Typography>
                        <span>{data}</span>
                      </Typography>
                    </li>
                  ))}
                </ul>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} style={{ marginTop: "2.13rem" }}>
            <Button
              variant="contained"
              onClick={handleFertigation}
              style={{
                display: "flex",
                padding: "1rem 1.5rem",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.25rem",
                borderRadius: "0.5rem",
                background: "#006BE0"
              }}
            >
              Start Fertigation
            </Button>
          </Grid>
          {fertigatedata && (
            <Grid item xs={12}>
              <Grid item xs={12} style={{ marginTop: "2rem" }}>
                <Typography
                  style={{
                    color: "rgba(0, 0, 0, 0.80)",
                    fontSize: "1.25rem",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal"
                  }}
                >
                  Nutrient Levels Over Time
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Plot
                  data={[
                    {
                      x: dataAgri.timestamp,
                      y: dataAgri.nitrogen,
                      type: "scatter",
                      mode: "lines",
                      name: "Nitrogen"
                    },
                    {
                      x: dataAgri.timestamp,
                      y: dataAgri.phosphorus,
                      type: "scatter",
                      mode: "lines",
                      name: "Phosphorus"
                    },
                    {
                      x: dataAgri.timestamp,
                      y: dataAgri.potassium,
                      type: "scatter",
                      mode: "lines",
                      name: "Potassium"
                    }
                  ]}
                  layout={{
                    title: "",
                    xaxis: { title: "Timestamp" },
                    yaxis: { title: "Nutrient Levels (kg/ha)" }
                  }}
                  style={{ width: "100%", height: "100%" }}
                />
              </Grid>
            </Grid>
          )}
          {data && (
            <Grid item xs={12}>
              <Grid item xs={12} style={{ marginTop: "2rem" }}>
                <Typography
                  style={{
                    color: "rgba(0, 0, 0, 0.80)",
                    fontSize: "1.25rem",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal"
                  }}
                >
                  Relationship Between Sowing Time and Spacing by Zone
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Plot
                  data={[
                    {
                      x: sowing_times,
                      y: spacing,
                      mode: "markers+text",
                      text: zones,
                      textposition: "top center",
                      marker: {
                        color: "skyblue",
                        size: 10,
                        opacity: 0.4
                      },
                      type: "scatter"
                    }
                  ]}
                  layout={{
                    title: "",
                    xaxis: {
                      title: "Sowing Time",
                      titlefont: { size: 14 }
                    },
                    yaxis: {
                      title: "Spacing Between Plants (cm)",
                      titlefont: { size: 14 }
                    },
                    showlegend: false,
                    annotations: zones.map((zone, i) => ({
                      x: sowing_times[i],
                      y: spacing[i],
                      xref: "x",
                      yref: "y",
                      //   text: zone,
                      showarrow: true,
                      arrowhead: 0.2,
                      ax: 20,
                      ay: -30
                    })),
                    grid: { rows: 1, columns: 1, pattern: "independent" },
                    plot_bgcolor: "white"
                  }}
                  style={{ width: "90%", height: "100%" }}
                />
              </Grid>
            </Grid>
          )}
          {data && (
            <Grid item xs={12}>
              <Grid item xs={12} style={{ marginTop: "2rem" }}>
                <Typography
                  style={{
                    color: "rgba(0, 0, 0, 0.80)",
                    fontSize: "1.25rem",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal"
                  }}
                >
                  Temperature Variation Across Crop Stages
                </Typography>
              </Grid>

              <Grid item xs={12}>
              <Plot
                data={[
                  {
                    x: cropStages,
                    y: minTemp,
                    type: "scatter",
                    mode: "lines+markers",
                    name: "Minimum Temp (°C)",
                    line: { color: "blue" },
                    marker: { symbol: "circle", size: 8 }
                  },
                  {
                    x: cropStages,
                    y: maxTemp,
                    type: "scatter",
                    mode: "lines+markers",
                    name: "Maximum Temp (°C)",
                    line: { color: "red" },
                    marker: { symbol: "square", size: 8 }
                  }
                ]}
                layout={{
                  title: "",
                  xaxis: {
                    title: "Crop Stage",
                    tickangle: -45
                  },
                  width: 1200,
                  yaxis: {
                    title: "Temperature (°C)",
                    gridcolor: "rgba(0, 0, 0, 0.2)",
                    gridwidth: 2,
                    zeroline: false
                  },
                  plot_bgcolor: "white",
                  legend: {
                    x: 1,
                    xanchor: "right",
                    y: 1.2
                  },
                  margin: {
                    t: 90,
                    b: 200,
                    l: 120
                  }
                }}
                style={{width: '100%', height: '100%'}}
              />
              </Grid>
              <Grid item xs={12} style={{ marginTop: "4rem" }}>
                <Typography
                  style={{
                    color: "rgba(0, 0, 0, 0.80)",
                    fontSize: "1.25rem",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal"
                  }}
                >
                  Water Use per Growth Stage (Soybean)
                </Typography>
              </Grid>

              <Grid item xs={12}>
              <Plot
                data={[
                  {
                    x: growthStages,
                    y: waterUse,
                    type: "bar",
                    marker: {
                      color: "skyblue"
                    }
                  }
                ]}
                layout={{
                  title: "",
                  xaxis: {
                    title: "Growth Stage",
                    tickangle: -45
                  },
                  width: 1200,
                  yaxis: {
                    title: "Water Use (inches/day)",
                    gridcolor: "rgba(0, 0, 0, 0.2)",
                    gridwidth: 2,
                    zeroline: false
                  },
                  plot_bgcolor: "white",
                  margin: {
                    t: 50,
                    b: 180
                  }
                }}
              />
              </Grid>
              <Grid item xs={12} style={{ marginTop: "4rem" }}>
                <Typography
                  style={{
                    color: "rgba(0, 0, 0, 0.80)",
                    fontSize: "1.25rem",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal"
                  }}
                >
                  Nutrient Amounts Applied over Time
                </Typography>
              </Grid>

              <Grid item xs={12}>
              <Plot data={[...plots1, ...bars]} layout={layout} />
              </Grid>
            </Grid>
          )}
        </Grid>
        <div
          style={{
            width: "100%",
            marginTop: 20,
            marginBottom: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
          }}
        >
          {/* <Row noGutters className="page-header py-4">
            <Col>
              <FormControl style={{ marginTop: "12px", width: 650 }}>
                <InputLabel id="asset-select-label">Crop Stages</InputLabel>

                <Select
                  labelId="asset-select-label"
                  id="asset-select"
                  name="cropType"
                  value={stag}
                  onChange={e => handleChange(e)}
                  label="Select Stages"
                >
                  {Object.values(stage).map(crop => {
                    return (
                      <MenuItem key={crop} value={crop}>
                        {crop}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Col>
          </Row> */}
          {/* <Row noGutters>
            <Box sx={{ width: 650 }}>
              <Typography variant="h8">
                Select Current Soil Moisture (in inches/day):
              </Typography>
              <Slider
                marks={[
                  {
                    value: 0.0,
                    label: "0.0"
                  },
                  {
                    value: 1.0,
                    label: "1.0"
                  }
                ]}
                step={0.01}
                value={val.moisture}
                name="moisture"
                valueLabelDisplay="auto"
                min={0.0}
                max={1.0}
                onChange={e => setVal({ [e.target.name]: e.target.value })}
              />
            </Box>
          </Row> */}
          {is_between_10_to_19() ? (
            <>
              {/* <Row noGutters>
                <Col>
                  {Object.keys(stagData).map(
                    data =>
                      stag != Object.values(stagData[data])[0] && (
                        <Typography key={data}>
                          <span>{data} : </span>
                          <span>{Object.values(stagData[data])[0]}</span>
                        </Typography>
                      )
                  )}
                </Col>
              </Row> */}
              {/* <Row noGutters className="py-4">
                {stagData ? (
                  <Col>
                    <Plot
                      data={[
                        {
                          x: ["Nitrogen", "Phosphorus", "Potassium"],
                          y: [nitrogen, phosphorus, potassium],
                          type: "bar",
                          marker: {
                            color: ["blue", "green", "orange"]
                          }
                        }
                      ]}
                      layout={{
                        title: `Nutrient Levels for ${stag}`,
                        xaxis: {
                          title: "Nutrients"
                        },
                        yaxis: {
                          title: "kg/ha"
                        },
                        width: 800,
                        height: 500
                      }}
                    />
                  </Col>
                ) : (
                  <Col>No Data Found</Col>
                )}
              </Row> */}
              {/* <Row noGutters>
                <Button variant="contained" onClick={handleFertigation}>
                  Start Fertigation
                </Button>
              </Row> */}
              {/* <Row noGutters className="py-4">
                <Col>
                  {fertigatedata
                    ? fertigatedata.map((data, index) => {
                        return (
                          <Typography key={index}>
                            <span>{data}</span>
                          </Typography>
                        );
                      })
                    : ""}
                </Col>
              </Row> */}
              {/* <Row noGutters className="py-4">
                {fertigatedata ? (
                  <Col>
                    <Plot
                      data={[
                        {
                          x: dataAgri.timestamp,
                          y: dataAgri.nitrogen,
                          type: "scatter",
                          mode: "lines",
                          name: "Nitrogen"
                        },
                        {
                          x: dataAgri.timestamp,
                          y: dataAgri.phosphorus,
                          type: "scatter",
                          mode: "lines",
                          name: "Phosphorus"
                        },
                        {
                          x: dataAgri.timestamp,
                          y: dataAgri.potassium,
                          type: "scatter",
                          mode: "lines",
                          name: "Potassium"
                        }
                      ]}
                      layout={{
                        title: "Nutrient Levels Over Time",
                        xaxis: { title: "Timestamp" },
                        yaxis: { title: "Nutrient Levels (kg/ha)" }
                      }}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Col>
                ) : (
                  <Col></Col>
                )}
              </Row> */}
            </>
          ) : (
            <Typography style={{ color: "red" }} className="py-4">
              Fertigation is scheduled between 10 AM to 7 PM. Please wait for
              the preferred time slot.
            </Typography>
          )}
        </div>

        <Row noGutters className="py-4">
          {/* {data ? (
            <Col>
              <Plot
                data={[
                  {
                    x: sowing_times,
                    y: spacing,
                    mode: "markers+text",
                    text: zones,
                    textposition: "top center",
                    marker: {
                      color: "skyblue",
                      size: 10,
                      opacity: 0.4
                    },
                    type: "scatter"
                  }
                ]}
                layout={{
                  title: "Relationship Between Sowing Time and Spacing by Zone",
                  xaxis: {
                    title: "Sowing Time",
                    titlefont: { size: 14 }
                  },
                  yaxis: {
                    title: "Spacing Between Plants (cm)",
                    titlefont: { size: 14 }
                  },
                  showlegend: false,
                  annotations: zones.map((zone, i) => ({
                    x: sowing_times[i],
                    y: spacing[i],
                    xref: "x",
                    yref: "y",
                    //   text: zone,
                    showarrow: true,
                    arrowhead: 0.2,
                    ax: 20,
                    ay: -30
                  })),
                  grid: { rows: 1, columns: 1, pattern: "independent" },
                  plot_bgcolor: "white"
                }}
              />
            </Col>
          ) : (
            <Col>No Data Found</Col>
          )} */}
          {/* {data ? (
            <Col className="py-4">
              <Plot
                data={[
                  {
                    x: stages,
                    y: etValuesNumeric,
                    type: "bar",
                    marker: {
                      color: "lightgreen"
                    }
                  }
                ]}
                layout={{
                  title: "Estimated Evapotranspiration Across Growth Stages",
                  xaxis: {
                    title: "Crop Growth Stage",
                    tickangle: -45
                  },
                  yaxis: {
                    title: "Estimated ET (inches/day)",
                    gridcolor: "rgba(0, 0, 0, 0.2)",
                    gridwidth: 2,
                    zeroline: false
                  },
                  plot_bgcolor: "white",
                  margin: {
                    t: 50,
                    b: 150
                  }
                }}
              />
            </Col>
          ) : (
            <Col>No Data Found</Col>
          )} */}
        </Row>
        {/* <Row noGutters className="py-4">
          {data ? (
            <Col>
              <Plot
                data={[
                  {
                    x: cropStages,
                    y: minTemp,
                    type: "scatter",
                    mode: "lines+markers",
                    name: "Minimum Temp (°C)",
                    line: { color: "blue" },
                    marker: { symbol: "circle", size: 8 }
                  },
                  {
                    x: cropStages,
                    y: maxTemp,
                    type: "scatter",
                    mode: "lines+markers",
                    name: "Maximum Temp (°C)",
                    line: { color: "red" },
                    marker: { symbol: "square", size: 8 }
                  }
                ]}
                layout={{
                  title: "Temperature Variation Across Crop Stages",
                  xaxis: {
                    title: "Crop Stage",
                    tickangle: -45
                  },
                  width: 1200,
                  yaxis: {
                    title: "Temperature (°C)",
                    gridcolor: "rgba(0, 0, 0, 0.2)",
                    gridwidth: 2,
                    zeroline: false
                  },
                  plot_bgcolor: "white",
                  legend: {
                    x: 1,
                    xanchor: "right",
                    y: 1.2
                  },
                  margin: {
                    t: 90,
                    b: 200,
                    l: 120
                  }
                }}
              />
            </Col>
          ) : (
            <Col>No Data Found</Col>
          )}
          {data ? (
            <Col className="py-4">
              <Plot
                data={[
                  {
                    x: growthStages,
                    y: waterUse,
                    type: "bar",
                    marker: {
                      color: "skyblue"
                    }
                  }
                ]}
                layout={{
                  title: "Water Use per Growth Stage (Soybean)",
                  xaxis: {
                    title: "Growth Stage",
                    tickangle: -45
                  },
                  width: 1200,
                  yaxis: {
                    title: "Water Use (inches/day)",
                    gridcolor: "rgba(0, 0, 0, 0.2)",
                    gridwidth: 2,
                    zeroline: false
                  },
                  plot_bgcolor: "white",
                  margin: {
                    t: 50,
                    b: 180
                  }
                }}
              />
            </Col>
          ) : (
            <Col>No Data Found</Col>
          )}
        </Row> */}
        <Row noGutters className="py-4">
          {/* {
                        data  ?
                            <Col>
                                <Plot
                                   data={plots}
                                   layout={layout}
                                />
                            </Col>
                            : <Col>No Data Found</Col>
                    } */}
          {data ? (
            <Col>
              <Plot data={[...plots1, ...bars]} layout={layout} />
            </Col>
          ) : (
            <Col>No Data Found</Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default management_practices;
