import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Container, Row } from "shards-react";
import PageTitle from "../components/common/PageTitle";

const soilTypeDetector = () => {
  const [imagePreview, setImagePreview] = useState(null);
  const [prediction, setImagePrediction] = useState(null);
   const [loading, setLoading] = useState(false)

  const handleChange = async event => {
    event.preventDefault();
    const selectedFile = event.target.files[0];
    const formData = new FormData();
    formData.append("file", selectedFile);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = e => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(selectedFile);
    }

    try {
      setLoading(true)
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/get/soil/type/predict`,
        formData,
        {
          "Content-Type": "multipart/form-data"
        }
      );
      if (response.data.status == "success") {
        setLoading(false)
        setImagePrediction(response.data);
      }
    } catch (error) {
      setLoading(false)
      console.error("Error Prediction Soil Type:", error);
    }
  };

  return (
    <Container
      fluid
      className="main-content-container px-4 py-4"
      style={{ backgroundColor: "white" }}
    >
      {/* <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Soil Type Detection"
          className="text-sm-left"
        />
      </Row> */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={7} xl={7}>
          <Grid item xs={12}>
            <Typography
              style={{
                color: "rgba(0, 0, 0, 0.80)",
                fontFeatureSettings: "'ss01' on, 'cv01' on, 'cv11' on",
                fontFamily: "Inter, sans-serif",
                fontSize: "1.5rem",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "1.125rem"
              }}
            >
              Soil type Detection
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "2.25rem" }}>
            <Typography
              style={{
                color: "rgba(0, 0, 0, 0.80)",
                fontFamily: "Inter, sans-serif",
                fontSize: "1.25rem",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal"
              }}
            >
              Upload an image of a soil to predict.
            </Typography>
          </Grid>
          <Grid item xs={12} marginTop="1.25rem">
            <Button
              variant="contained"
              component="label"
              style={{
                display: "flex",
                width: "8.75rem",
                padding: "1rem 1.5rem",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.25rem",
                borderRadius: "0.5rem",
                background: "#006BE0"
              }}
            >
              { loading ? <CircularProgress size={27} sx={{ color: 'white'}}/> : "Upload"}
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={handleChange}
              />
            </Button>
          </Grid>
          {prediction && (
        <>
                <Grid
          item
          xs={12}
          style={{
            marginBottom: '1.75rem',
            marginTop: '1.75rem',
            borderRadius: "0.75rem",
            border: "1px solid rgba(245, 138, 32, 0.60)",
            backgroundColor: "#FFF",
            padding: "1rem",
            boxShadow: `
    1px 1px 2px 0px rgba(255, 255, 255, 0.30) inset,
    -1px -1px 2px 0px rgba(239, 239, 239, 0.50) inset,
    -4px 4px 8px 0px rgba(239, 239, 239, 0.20),
    4px -4px 8px 0px rgba(239, 239, 239, 0.20),
    -4px -4px 8px 0px rgba(255, 255, 255, 0.90),
    4px 4px 10px 0px rgba(239, 239, 239, 0.90)
  `
          }}
        >
          <Grid container>
            <Grid item xs={12} style={{ paddingBottom: "1.25rem" }}>
              <Typography
                variant="h8"
                style={{
                  color: "#181818",
                  fontSize: "1.25rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal"
                }}
              >
                Predicted Soil Type:
              </Typography>
            </Grid>
            <Grid item xs={12}>
            {prediction.soil_type} 
            </Grid>
          </Grid>
        </Grid>
                <Grid
          xs={12}
          item
          style={{
            marginBottom: '1.75rem',
            borderRadius: "0.75rem",
            border: "1px solid rgba(245, 138, 32, 0.60)",
            background: "#FFF",
            padding: "1rem",
            boxShadow: `
    1px 1px 2px 0px rgba(255, 255, 255, 0.30) inset,
    -1px -1px 2px 0px rgba(239, 239, 239, 0.50) inset,
    -4px 4px 8px 0px rgba(239, 239, 239, 0.20),
    4px -4px 8px 0px rgba(239, 239, 239, 0.20),
    -4px -4px 8px 0px rgba(255, 255, 255, 0.90),
    4px 4px 10px 0px rgba(239, 239, 239, 0.90)
  `
          }}
        >
          <Grid container>
            <Grid item xs={12} style={{ paddingBottom: "1.25rem" }}>
              <Typography
                variant="h8"
                style={{
                  color: "#181818",
                  fontSize: "1.25rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal"
                }}
              >
                Suitable Crops:
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ 
    display: 'flex', 
    flexWrap: 'wrap', 
  }} >
            {prediction.crops.map((crop, index) => {
                const hasMultipleWords = crop.trim().split(/\s+/).length > 1;
                
              return <span key={index} style={hasMultipleWords ? { whiteSpace: 'nowrap' } : { whiteSpace: 'normal' }}>{crop}{index < prediction.crops.length - 1 && ','}</span>
            })}
            </Grid>
          </Grid>
        </Grid>
                <Grid
          xs={12}
          item
          style={{
            marginBottom: '1.75rem',
            borderRadius: "0.75rem",
            border: "1px solid rgba(245, 138, 32, 0.60)",
            background: "#FFF",
            padding: "1rem",
            boxShadow: `
    1px 1px 2px 0px rgba(255, 255, 255, 0.30) inset,
    -1px -1px 2px 0px rgba(239, 239, 239, 0.50) inset,
    -4px 4px 8px 0px rgba(239, 239, 239, 0.20),
    4px -4px 8px 0px rgba(239, 239, 239, 0.20),
    -4px -4px 8px 0px rgba(255, 255, 255, 0.90),
    4px 4px 10px 0px rgba(239, 239, 239, 0.90)
  `
          }}
        >
          <Grid container>
            <Grid item xs={12} style={{ paddingBottom: "1.25rem" }}>
              <Typography
                variant="h8"
                style={{
                  color: "#181818",
                  fontSize: "1.25rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  textAlign: 'justify'
                }}
              >
                States Where {prediction.soil_type} is Found: 
              </Typography>
            </Grid>
            <Grid item xs={12}>
            {prediction.state_info}
            </Grid>
          </Grid>
        </Grid>
          {/* <Row noGutters className="py-3" style={{ width: "60%" }}>
            <span>
              Predicted Soil Type: <span>{prediction.soil_type}</span>
            </span>
          </Row> */}
          {/* <Row noGutters style={{ width: "60%" }}>
            <span>Suitable Crops:</span>
          </Row> */}
          {/* <Row noGutters style={{ width: "60%" }}>
            {prediction.crops.map((crop, index) => {
              return <span index={index}>{crop},</span>;
            })}
          </Row> */}
          {/* <Row noGutters className="py-3" style={{ width: "60%" }}>
            <span>States Where {prediction.soil_type} is Found: </span>
          </Row>
          <Row noGutters style={{ width: "60%" }}>
            <span>{prediction.state_info}</span>
          </Row> */}
        </>
      ) }
        </Grid>
        <Grid item xs={12} md={12} lg={5} xl={5} style={{ display: 'flex', justifyContent: 'flex-end'}}>
        {imagePreview && (
            <img
              src={imagePreview}
              alt="Selected"
              style={{
                width: '29.625rem',
                height: '19.75rem',
                flexShrink: 0,
                borderRadius: '2.5rem',
                backgroundPosition: "cover"
              }}
            />

          )}
        </Grid>
      </Grid>
      {/* <Row noGutters className="page-header py-2">
        <Button variant="contained" component="label">
          Upload
          <input type="file" accept="image/*" hidden onChange={handleChange} />
        </Button>
      </Row> */}
      {/* <Row noGutters className="py-3" style={{ width: "60%" }}>
        {imagePreview && (
          <div>
            <img
              src={imagePreview}
              alt="Selected"
              style={{ marginTop: "10px", maxWidth: "100%" }}
            />
          </div>
        )}
      </Row> */}

    </Container>
  );
};

export default soilTypeDetector;
