import { Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import React, { useState } from "react";
import Plot from "react-plotly.js";
import { Col, Row } from "shards-react";

const SoyabeanSeedDetection = ({ predictDisease, imagePreview, handleChange, model, setModel }) => {
    // const [model, setModel] = useState("tomato_leaf_disease_model_vgg16.h5");

    let models = [
        {
            id: 1,
            name: "VGG16",
            value: 'VGG16_best_model.h5'
        },
        {
            id: 2,
            name: "ResNet50",
            value: 'ResNet50_best_model.h5'
        },
        {
            id: 3,
            name: "InceptionV3",
            value: 'InceptionV3_best_model.h5'
        },
        {
            id: 4,
            name: "EfficientNet",
            value: 'EfficientNetB0_best_model.h5'
        },
    ];

    const handleInputChange = e => {
        setModel(e.target.value);
    };

    return (
        <>

                        <Select
                            labelId="asset-select-label"
                            id="asset-select"
                            name="ModelType"
                            value={model}
                            onChange={e => handleInputChange(e)}
                            style={{ width: "20rem", height: "4.5rem" }}

                        >
                            {models.map(model => {
                                return (
                                    <MenuItem key={model.id} value={model.value}>
                                        {model.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
            {/* <Button
                variant="contained"
                component="label"

            >
                Upload
                <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={handleChange}
                />
            </Button> */}
            {/* <Row noGutters className="py-3" style={{ width: '50%' }}>
                {imagePreview && (
                    <div>
                        <img src={imagePreview} alt="Selected" style={{ marginTop: '10px'}} />
                    </div>
                )}
            </Row> */}

           

        </>
    )
}


export default SoyabeanSeedDetection