import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import { Col, Container, Row } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import axios from "axios";
import Plot from "react-plotly.js";
import SoyabeanDiseaseDetection from "./SoyabeanDiseaseDetection";
import TomatoDiseaseDetection from "./TomatoDiseaseDetection";
const DiseaseDetection = () => {
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [crop, setSelectedCrop] = useState("soyabean");
  const [predictDisease, setPredictDisease] = useState();
  const [model, setModel] = useState("tomato_leaf_disease_model_VGG16.h5");
   const [loading, setLoading] = useState(false)

  let crops = [
    {
      id: 1,
      name: "soyabean"
    },
    // {
    //   id: 2,
    //   name: "Tomato"
    // }
  ];

  const handleInputChange = e => {
    setImagePreview(null);
    setPredictDisease();
    setSelectedCrop(e.target.value);
  };
  const handleChange = async event => {
    event.preventDefault();
    const selectedFile = event.target.files[0];
    const formData = new FormData();
    formData.append("file", selectedFile);
    setFile(selectedFile);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = e => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(selectedFile);
    }

    if (crop == "soyabean") {
      try {
        setLoading(true)
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/get/soyabean/disease/prediction`,
          formData,
          {
            "Content-Type": "multipart/form-data"
          }
        );
        if (response.data.status == "success") {
            setLoading(false)
          
          setPredictDisease(response.data);
        }
      } catch (error) {
        setLoading(false)

        console.error("Error uploading file:", error);
      }
    } else {
      formData.append("model", model);
      try {
        setLoading(true)

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/get/tomato/disease/prediction`,
          formData,
          {
            "Content-Type": "multipart/form-data"
          }
        );
        if (response.data.status == "success") {
          setLoading(false)
          setPredictDisease(response.data);
        }
      } catch (error) {
        setLoading(false)
        console.error("Error uploading file:", error);
      }
    }
  };
  return (
    <Container
      fluid
      className="main-content-container px-4 py-4"
      style={{ backgroundColor: "white" }}
    >
      {/* <Row noGutters className="page-header py-2">
        <Col>
          <FormControl style={{ marginTop: "12px" }}>
            <InputLabel id="asset-select-label">Crop Type</InputLabel>

            <Select
              labelId="asset-select-label"
              id="asset-select"
              name="cropType"
              value={crop}
              onChange={e => handleInputChange(e)}
              label="Select category"
              style={{ width: 165 }}
            >
              {crops.map(crop => {
                return (
                  <MenuItem key={crop.id} value={crop.name}>
                    {crop.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Col>
      </Row> */}
      {/* <Row noGutters style={{ fontSize: "20px" }} className="page-header py-1">
        <PageTitle
          sm="4"
          title={`${crop} Leaf Disease Detection`}
          subtitle=""
          className="text-sm-left"
        />
      </Row> */}
      {/* <Row noGutters className="py-3" style={{ fontSize: "13px" }}>
        Upload an image of a {crop} leaf to predict its disease.
      </Row> */}

      {/* {crop == "soyabean" && (
        <SoyabeanDiseaseDetection
          predictDisease={predictDisease}
          imagePreview={imagePreview}
          handleChange={handleChange}
        />
      )}
      {crop == "Tomato" && (
        <TomatoDiseaseDetection
          predictDisease={predictDisease}
          imagePreview={imagePreview}
          handleChange={handleChange}
          model={model}
          setModel={setModel}
        />
      )} */}
      <Grid container>
        <Grid item xs={4}>
          <Grid item xs={12} style={{ marginBottom: "0.5rem" }}>
            <Typography
              style={{
                color: "rgba(0, 0, 0, 0.80)",
                fontSize: "1.25rem",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal"
              }}
            >
              Crop type
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Select
              labelId="asset-select-label"
              id="asset-select"
              name="cropType"
              value={crop}
              onChange={e => handleInputChange(e)}
              style={{ width: "20rem", height: "4.5rem" }}
            >
              {crops.map(crop => {
                return (
                  <MenuItem key={crop.id} value={crop.name}>
                    {crop.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>
        {crop == "Tomato" && (
          <Grid item xs={4}>
            <Grid item xs={12} style={{ marginBottom: "0.5rem" }}>
              <Typography
                style={{
                  color: "rgba(0, 0, 0, 0.80)",
                  fontSize: "1.25rem",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal"
                }}
              >
                Model type
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TomatoDiseaseDetection
                predictDisease={predictDisease}
                imagePreview={imagePreview}
                handleChange={handleChange}
                model={model}
                setModel={setModel}
              />
            </Grid>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          style={{ marginBottom: "1.25rem", marginTop: "2rem" }}
        >
          <Typography
            style={{
              color: "rgba(0, 0, 0, 0.80)",
              fontSize: "1.25rem",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal"
            }}
          >
            Upload an image of {crop} Leaf to predict.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            component="label"
            style={{
              display: "flex",
              width: "8.75rem",
              padding: "1rem 1.5rem",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.25rem",
              borderRadius: "0.5rem",
              background: "#006BE0"
            }}
          >
            { loading ? <CircularProgress size={27} sx={{ color: 'white'}}/> : "Upload"}
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={handleChange}
            />
          </Button>
        </Grid>
        {imagePreview && (
          <Grid item xs={12} style={{ marginTop: "2.5rem" }}>
            <Typography
              style={{
                color: "rgba(0, 0, 0, 0.80)",
                fontFamily: "Inter, sans-serif",
                fontSize: "0.875rem",
                fontStyle: "italic",
                fontWeight: 400,
                lineHeight: "normal"
              }}
            >
              Uploaded image
            </Typography>
          </Grid>
        )}
        <Grid item style={{ marginTop: "0.5rem" }}>
          {imagePreview && (
            <img
              src={imagePreview}
              alt="Selected"
              style={{
                width: "18.75rem",
                height: "12.75rem",
                flexShrink: 0,
                borderRadius: "1.25rem",
                backgroundPosition: "cover"
              }}
            />
          )}
        </Grid>
        {crop == "soyabean" && predictDisease && (
          <Grid item style={{ marginTop: "0.5rem", marginLeft: "3rem" }}>
            <Box
              sx={{
                position: "relative",
                display: "inline-block",
                border: "1px solid #828282",
                borderRadius: "0.25rem",
                overflow: "hidden"
              }}
            >
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        backgroundColor: "#EBEBEB",
                        gap: "0.625rem",
                        borderRight: "1px solid #828282",
                        borderBottom: "1px solid #828282"
                      }}
                    >
                      Prediction
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem",
                        borderBottom: "1px solid #828282"
                      }}
                    >
                      {predictDisease.predictions}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem",
                        backgroundColor: "#EBEBEB",
                        borderRight: "1px solid #828282"
                      }}
                    >
                      Confidence
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem"
                      }}
                    >
                      {predictDisease.confidence}%
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Grid>
        )}

        {crop == "Tomato" && predictDisease && (
          <>
            <Grid
              item
              xs={12}
              style={{
                marginTop: "2.5rem",
                alignItems: "center",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Typography
                style={{
                  color: "rgba(0, 0, 0, 0.80)",
                  fontSize: "1.5rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal"
                }}
              >
                Prediction:
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Typography
                style={{
                  color: "#FFFFFF",
                  fontFamily: "Inter",
                  fontSize: "1.5rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  padding: "0.75rem 1.5rem",
                  borderRadius: "0.75rem",
                  border: "4px solid #F58A20",
                  background: "#0E2748"
                }}
              >
                {predictDisease.predicted_class} with{" "}
                {predictDisease.confidence}% confidence
              </Typography>
            </Grid>
          </>
        )}
        {crop == "Tomato" && predictDisease && (
          <Grid item xs={12}>
            <Plot
              style={{ width: "100%", height: "100%" }}
              data={[
                {
                  type: "bar",
                  x: predictDisease.predictions[0].map(p => p),
                  y: Object.values(predictDisease.class_labels),
                  orientation: "h"
                }
              ]}
              layout={{
                title: "Class Probabilities",
                xaxis: {
                  title: "Probability"
                },
                yaxis: {
                  automargin: true
                },
              }}
            />
          </Grid>
        )}
        {crop == "soyabean" && predictDisease && (
          <Grid item xs={12} style={{ marginTop: "1.25rem" }}>
            <Typography
              style={{
                color: "rgba(0, 0, 0, 0.80)",
                fontSize: "1rem",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal"
              }}
            >
              Prediction Probabilities
            </Typography>
          </Grid>
        )}
        {crop == "soyabean" && predictDisease && (
          <Grid item xs={12} style={{ marginTop: "0.5rem" }}>
            <Box
              sx={{
                position: "relative",
                display: "inline-block",
                border: "1px solid #828282",
                borderRadius: "0.25rem",
                overflow: "hidden"
              }}
            >
              <Table>
                <TableBody>
                  {predictDisease.prediction_probabilities
                    ? predictDisease.prediction_probabilities.map(
                        (data, index) => {
                          return (
                            <TableRow>
                              <TableCell
                                style={{
                                  padding: "0.625rem 0.5rem",
                                  alignItems: "center",
                                  backgroundColor: "#EBEBEB",
                                  gap: "0.625rem",
                                  borderRight: "1px solid #828282",
                                  borderBottom: "1px solid #828282"
                                }}
                              >
                                {data.class_name}
                              </TableCell>
                              <TableCell
                                style={{
                                  padding: "0.625rem 0.5rem",
                                  alignItems: "center",
                                  gap: "0.625rem",
                                  borderBottom: "1px solid #828282"
                                }}
                              >
                                {data.formatted_probability}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )
                    : ""}
                </TableBody>
              </Table>
            </Box>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default DiseaseDetection;
