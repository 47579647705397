import { Grid } from "@mui/material"
import React, { useEffect, useState } from "react"
import Plot from 'react-plotly.js';
import axios from "axios"
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Container, Row } from "shards-react";
import PageTitle from "../components/common/PageTitle";

const SoyaAnalysis = () => {
  const [analysis, setAnalysis] = useState({});
  const [analysisSet, setAnalysisSet] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/agri/soyabean/nutrient/prediction`);
        if (response.data.status === 'success') {
          setAnalysis(response.data);

        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  if (Object.keys(analysis).length === 0) {
    return <div>Loading...</div>; // Or any loading indicator you prefer
  }

  const nutrients = Object.keys(analysis.result);
  const zones = Object.keys(analysis.result[nutrients[0]]);

  // Plotly trace for each nutrient
  const traces = zones.map(zone => ({
    x: nutrients,
    y: nutrients.map(nutrient => analysis.result[nutrient][zone]),
    type: 'bar',
    name: zone
  }));

  const binSize = 0.1;
  const numBins = Math.ceil((Math.max(...Object.values(analysis.ph)) - Math.min(...Object.values(analysis.ph))) / binSize);
  const histogramFrequencies = Array(numBins).fill(0);

  Object.values(analysis.ph).forEach(value => {
    const binIndex = Math.floor((value - Math.min(...Object.values(analysis.ph))) / binSize);
    histogramFrequencies[binIndex]++;
  });
  const histogramData = [
    {
      x: Object.values(analysis.ph),
      y: histogramFrequencies,
      type: 'histogram',
      marker: { color: 'rgba(100, 149, 237, 0.6)' },
      opacity: 0.7,
      autobinx: false,
      xbins: { size: 0.1, start: Math.min(...Object.values(analysis.ph)), end: Math.max(...Object.values(analysis.ph)) },
    },
  ];
  // Layout for the plot
  const layout = {
    title: 'Nutrient Levels by Zone',
    xaxis: { title: 'Nutrient' },
    yaxis: { title: 'Nutrient Level' },
    width: 1200,
    height: 400
  };

  // Create vertical lines for thresholds
  const shapes = [
    {
      type: 'line',
      x0: analysis.lower_threshold,
      y0: 0,
      x1: analysis.lower_threshold,
      y1: Math.max(...histogramFrequencies),
      line: {
        color: 'red',
        dash: 'dash',
        width: 1,
      },
    },
    {
      type: 'line',
      x0: analysis.upper_threshold,
      y0: 0,
      x1: analysis.upper_threshold,
      y1: Math.max(...histogramFrequencies),
      line: {
        color: 'green',
        dash: 'dash',
        width: 1,
      },
    },
  ];

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ color: "#334474" }}>{"ID"}</strong>,
    },
    {
      field: "timestamp",
      headerName: "Timestamp",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#334474" }}>{"Timestamp"}</strong>
      ),
    },
    {
      field: "nitrogen",
      headerName: "Nitrogen",
      width: 80,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#334474" }}>{"Nitrogen"}</strong>
      ),
    },
    {
      field: "Central_nitrogen_deficiency",
      headerName: "Central_nitrogen_deficiency",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ color: "#334474" }}>{"Central Nitrogen Deficiency"}</strong>,
    },
    {
      field: "Central_nitrogen_excess",
      headerName: "Central_nitrogen_excess",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#334474" }}>{"Central Nitrogen Excess"}</strong>
      ),
    },
    {
      field: "Southern_nitrogen_deficiency",
      headerName: "Southern_nitrogen_deficiency",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"South Nitrogen Deficiency"}</strong>
      ),
    },
    {
      field: "Southern_nitrogen_excess",
      headerName: "Southern_nitrogen_excess",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"South Nitrogen Excess"}</strong>
      ),
    },
    {
      field: "North Plain_nitrogen_deficiency",
      headerName: "Plain_nitrogen_deficiency",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"North Plain Nitrogen Deficiency"}</strong>
      ),
    },
    {
      field: "North Plain_nitrogen_excess",
      headerName: "Plain_nitrogen_excess",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"North Plain Nitrogen Excess"}</strong>
      ),
    },
    {
      field: "North Eastern_nitrogen_deficiency",
      headerName: "North Eastern_nitrogen_deficiency",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"North Eastern Nitrogen Deficiency"}</strong>
      ),
    },
    {
      field: "North Eastern_nitrogen_excess",
      headerName: "North Eastern_nitrogen_excess",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"North Eastern Nitrogen Excess"}</strong>
      ),
    },
    {
      field: "Eastern_nitrogen_deficiency",
      headerName: "Eastern_nitrogen_deficiency",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"East Nitrogen Deficiency"}</strong>
      ),
    },
    {
      field: "Eastern_nitrogen_excess",
      headerName: "Eastern_nitrogen_excess",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"East Nitrogen Excess"}</strong>
      ),
    },
    {
      field: "phosphorus",
      headerName: "phosphorus",
      width: 80,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#334474" }}>{"Phosphorus"}</strong>
      ),
    },
    {
      field: "Central_phosphorus_deficiency",
      headerName: "Central_phosphorus_deficiency",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ color: "#334474" }}>{"Central Phosphorus Deficiency"}</strong>,
    },
    {
      field: "Central_phosphorus_excess",
      headerName: "Central_phosphorus_excess",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#334474" }}>{"Central Phosphorus Excess"}</strong>
      ),
    },
    {
      field: "Southern_phosphorus_deficiency",
      headerName: "Southern_phosphorus_deficiency",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"South Phosphorus Deficiency"}</strong>
      ),
    },
    {
      field: "Southern_phosphorus_excess",
      headerName: "Southern_phosphorus_excess",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"South Phosphorus Excess"}</strong>
      ),
    },
    {
      field: "North Plain_phosphorus_deficiency",
      headerName: "North Plain_phosphorus_deficiency",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"North Plain Phosphorus Deficiency"}</strong>
      ),
    },
    {
      field: "North Plain_phosphorus_excess",
      headerName: "North Plain_phosphorus_excess",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"North Plain Phosphorus Excess"}</strong>
      ),
    },
    {
      field: "North Eastern_phosphorus_deficiency",
      headerName: "North Eastern_phosphorus_deficiency",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"North Eastern Phosphorus Deficiency"}</strong>
      ),
    },
    {
      field: "North Eastern_phosphorus_excess",
      headerName: "North Eastern_phosphorus_excess",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"North Eastern Phosphorus Excess"}</strong>
      ),
    },
    {
      field: "Eastern_phosphorus_deficiency",
      headerName: "Eastern_phosphorus_deficiency",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"East Phosphorus Deficiency"}</strong>
      ),
    },
    {
      field: "Eastern_phosphorus_excess",
      headerName: "Eastern_phosphorus_excess",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"East Phosphorus Excess"}</strong>
      ),
    },

    {
      field: "potassium",
      headerName: "potassium",
      width: 80,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#334474" }}>{"Potassium"}</strong>
      ),
    },
    {
      field: "Central_potassium_deficiency",
      headerName: "Central_potassium_deficiency",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ color: "#334474" }}>{"Central Potassium Deficiency"}</strong>,
    },
    {
      field: "Central_potassium_excess",
      headerName: "Central_potassium_excess",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#334474" }}>{"Central Potassium Excess"}</strong>
      ),
    },
    {
      field: "Southern_potassium_deficiency",
      headerName: "Southern_potassium_deficiency",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"South Potassium Deficiency"}</strong>
      ),
    },
    {
      field: "Southern_potassium_excess",
      headerName: "Battery",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"South Potassium Excess"}</strong>
      ),
    },
    {
      field: "North Plain_potassium_deficiency",
      headerName: "North Plain_potassium_deficiency",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"North Plain Potassium Deficiency"}</strong>
      ),
    },
    {
      field: "North Plain_potassium_excess",
      headerName: "North Plain_potassium_excess",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"North Plain Potassium Excess"}</strong>
      ),
    },
    {
      field: "North Eastern_potassium_deficiency",
      headerName: "North Eastern_potassium_deficiency",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"North Eastern Potassium Deficiency"}</strong>
      ),
    },
    {
      field: "North Eastern_potassium_excess",
      headerName: "North Eastern_potassium_excess",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"North Eastern Potassium Excess"}</strong>
      ),
    },
    {
      field: "Eastern_potassium_deficiency",
      headerName: "Eastern_potassium_deficiency",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"East Potassium Deficiency"}</strong>
      ),
    },
    {
      field: "Eastern_potassium_excess",
      headerName: "Eastern_potassium_excess",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"East Potassium Excess"}</strong>
      ),
    },

  ];

  const columnsDataSet = [
    {
      field: "id",
      headerName: "ID",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ color: "#334474" }}>{"ID"}</strong>,
    },
    {
      field: "ph",
      headerName: "ID",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ color: "#334474" }}>{"pH Value"}</strong>,
    },
    {
      field: "ph_analysis",
      headerName: "ID",
      width: 350,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ color: "#334474" }}>{"pH Analysis"}</strong>,
    },
  ];

  return (
    <Container fluid className="main-content-container px-4 pb-4" style={{ backgroundColor: "white" }}>
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="" subtitle="" className="text-sm-left" />
      </Row>
      <Container fluid className="px-2 h-500">
        <Grid container spacing={2}>
          {
            analysis ? <>
              <Grid item xs={12}>
                <Plot
                  data={traces}
                  layout={layout}
                />
              </Grid>
              {/* <Grid item >
                <Plot data={[
                  {
                    x: Object.keys(analysis.ph),
                    y: Object.values(analysis.ph),
                    type: 'scatter',
                    mode: 'lines',
                    marker: { color: 'blue' },
                  },
                ]} layout={{
                  title: 'ph',
                  xaxis: {
                    title: '',
                  },
                  yaxis: {
                    title: 'pH Value',
                  },
                  margin: {
                    l: 50,
                    r: 50,
                    b: 50,
                    t: 50,
                    pad: 4,
                  },
                }} />
              </Grid>
              <Grid item >

                <Plot
                  data={[{
                    x: Object.keys(analysis.pH_counts),
                    y: Object.values(analysis.pH_counts),
                    type: 'bar',
                    histnorm: 'probability',
                    autobinx: false,
                    xbins: {
                      size: 0.2,
                    },
                    marker: {
                      color: 'skyblue',
                      line: {
                        color: 'skyblue', // Border color
                        width: 1, // Border width
                      },
                    },
                    histfunc: 'count',
                  },]}
                  layout={
                    {
                      title: 'pH',
                      xaxis: { title: 'pH Value' },
                      yaxis: { title: 'Frequency' },
                      bargap: 0.1,
                      bargroupgap: 0.2,
                      grid: { rows: 1, columns: 1, pattern: 'independent' }
                    }
                  }
                />
              </Grid>
              <Grid item >
                <Plot
                  data={histogramData}
                  layout={{

                    title: 'Distribution of pH in the Dataset',
                    xaxis: { title: 'pH Value' },
                    yaxis: { title: 'Frequency' },
                    shapes: shapes,
                    margin: { t: 50, b: 50, l: 50, r: 50 },
                    autosize: true,
                    bargap: 0.05,
                    hovermode: 'closest',
                    showlegend: false,
                  }}
                />
              </Grid> */}
              <Grid item xs={12} lg={6} md={6} style={{ height: 400, width: '100%' }}>
                <DataGrid
                  rows={analysis.data}
                  columns={columns}
                  density="compact"
                  rowCount={100}
                  paginationMode="server"
                  components={{ Toolbar: GridToolbar }}
                />
              </Grid>


              <Grid item xs={12} lg={6} md={6} style={{ height: 400 }}>
                <DataGrid
                  rows={analysis.combined_ph_data}
                  columns={columnsDataSet}
                  density="compact"
                  rowCount={100}
                  paginationMode="server"
                  components={{ Toolbar: GridToolbar }}
                />
              </Grid>
            </> : <div>No Data</div>
          }

        </Grid>
      </Container>

    </Container>
  )
}

export default SoyaAnalysis;
