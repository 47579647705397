import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Editor from "../components/add-new-post/Editor";
import SidebarActions from "../components/add-new-post/SidebarActions";
import SidebarCategories from "../components/add-new-post/SidebarCategories";
import RecommendationForm from "../components/add-new-post/RecommendationForm";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography, Slider } from "@mui/material";

const AddNewPost = () => {
  const [cropRecommend, setCropRecommend] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/ai/recommendation_crop`,{
                  "temperature": val.temperature,
                  "humidity": val.humidity,
                  "nitrogen": val.nitrogen,
                  "potassium": val.potassium,
                  "phosphorus": val.phosphorus,
                  "ph": val.ph,
                  "rainfall": val.rainfall
          }
        );
        if (response.data.status == 'success') {
          setCropRecommend(response.data)
        }

      } catch (error) {
        console.error('Error fetching API:', error);
      }
    };

    // Call the functions
    fetchData();
    // fetchPlotlyGraphData();
  }, []);

  const columns = [
    {
      header: "id", width: 90, headerAlign: 'center', align: "center",
      filterable: false,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
      renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'SL No'}
        </strong>
      )
    },
    {
      field: "timestamp", width: 250, headerAlign: 'center', align: "center",
      filterable: false,
      renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'Time'}
        </strong>
      )
    },
    {
      field: "nitrogen", width: 90, headerAlign: 'center', align: "center",
      filterable: false,
      renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'Nitrogen'}
        </strong>
      )
    },
    {
      field: "phosphorus", width: 90, headerAlign: 'center', align: "center",
      filterable: false,
      renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'Phosphorus'}
        </strong>
      )
    },
    {
      field: "potassium", width: 90, headerAlign: 'center', align: "center",
      filterable: false,
      renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'Potassium'}
        </strong>
      )
    },
    {
      field: "temperature", width: 90, headerAlign: 'center', align: "center",
      filterable: false,
      renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'Temperature'}
        </strong>
      )
    },
    {
      field: "humidity", width: 90, headerAlign: 'center', align: "center",
      filterable: false,
      renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'Humidity'}
        </strong>
      )
    },
    {
      field: "ph", width: 90, headerAlign: 'center', align: "center",
      filterable: false,
      renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'pH'}
        </strong>
      )
    },
    {
      field: "rainfall", width: 140, headerAlign: 'center', align: "center",
      filterable: false,
      renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'RainFall'}
        </strong>
      )
    },

  ];

  let crop_management_practices = {
    "Rice": {
      "Sowing Time": "Tropical Areas: April to May, Temperate Areas: April to June",
      "Harvesting Time": "Tropical Areas: September to November, Temperate Areas: September to October",
      "Fertilizer Doses": "NPK: 120 kg N, 60 kg P, 40 kg K per hectare; Organic Matter: 10-15 tons of compost per hectare",
      "Irrigation": "Maintain 5-10 cm of standing water in fields during the growing season. Drain water 10-15 days before harvesting.",
      "Soil Type": "Well-drained loamy soil, clay soil preferred."
    },
    "Maize": {
      "Sowing Time": "Spring Planting: March to April, Summer Planting: June to July",
      "Harvesting Time": "Spring Planting: August to September, Summer Planting: October to November",
      "Fertilizer Doses": "NPK: 150 kg N, 50 kg P, 30 kg K per hectare; Organic Matter: 5-10 tons of compost per hectare",
      "Irrigation": "Regular irrigation required during dry spells, especially during flowering and grain formation. Ensure 25-30 mm of water per week.",
      "Soil Type": "Well-drained, fertile loam or sandy loam soil."
    },
    "Jute": {
      "Sowing Time": "Tropical Areas: May to June",
      "Harvesting Time": "Tropical Areas: August to September",
      "Fertilizer Doses": "NPK: 100 kg N, 40 kg P, 20 kg K per hectare; Organic Matter: 5-8 tons of compost per hectare",
      "Irrigation": "Ensure adequate water supply, especially during dry periods. Maintain moist soil conditions.",
      "Soil Type": "Water-retentive, alluvial soil."
    },
    "Cotton": {
      "Sowing Time": "Spring Planting: March to April",
      "Harvesting Time": "October to December",
      "Fertilizer Doses": "NPK: 60 kg N, 30 kg P, 30 kg K per hectare; Organic Matter: 5-10 tons of compost per hectare",
      "Irrigation": "Regular irrigation during dry spells, especially during flowering and boll formation.",
      "Soil Type": "Fertile, well-drained soil, preferably black soil or loamy soil."
    },
    "Coconut": {
      "Sowing Time": "All Year Round",
      "Harvesting Time": "Monthly or as required",
      "Fertilizer Doses": "NPK: 250 kg N, 125 kg P, 125 kg K per hectare annually; Organic Matter: 5-10 tons of compost per hectare",
      "Irrigation": "Ensure regular watering, especially during dry spells.",
      "Soil Type": "Well-drained sandy loam to loamy soil."
    },
    "Papaya": {
      "Sowing Time": "Spring to Summer",
      "Harvesting Time": "9-11 months after planting",
      "Fertilizer Doses": "NPK: 100 kg N, 50 kg P, 50 kg K per hectare; Organic Matter: 10 tons of compost per hectare",
      "Irrigation": "Regular watering, especially during dry periods. Ensure well-drained soil.",
      "Soil Type": "Well-drained sandy loam to loamy soil."
    },
    "Orange": {
      "Sowing Time": "Spring to Summer",
      "Harvesting Time": "October to March",
      "Fertilizer Doses": "NPK: 200 kg N, 100 kg P, 200 kg K per hectare; Organic Matter: 5-10 tons of compost per hectare",
      "Irrigation": "Regular watering, especially during dry spells.",
      "Soil Type": "Well-drained, sandy loam or loamy soil."
    },
    "Apple": {
      "Sowing Time": "Spring (March to April)",
      "Harvesting Time": "Late summer to fall (August to October)",
      "Fertilizer Doses": "NPK: 100 kg N, 50 kg P, 50 kg K per hectare; Organic Matter: 10 tons of compost per hectare",
      "Irrigation": "Regular irrigation, especially during dry periods.",
      "Soil Type": "Well-drained, loamy soil with a slightly acidic pH."
    },
    "Muskmelon": {
      "Sowing Time": "Spring to early summer",
      "Harvesting Time": "70-90 days after sowing",
      "Fertilizer Doses": "NPK: 100 kg N, 50 kg P, 50 kg K per hectare; Organic Matter: 5 tons of compost per hectare",
      "Irrigation": "Regular irrigation, especially during fruit development.",
      "Soil Type": "Well-drained sandy loam soil."
    },
    "Watermelon": {
      "Sowing Time": "Spring to early summer",
      "Harvesting Time": "80-90 days after sowing",
      "Fertilizer Doses": "NPK: 100 kg N, 50 kg P, 50 kg K per hectare; Organic Matter: 5 tons of compost per hectare",
      "Irrigation": "Regular irrigation, especially during fruit setting and development.",
      "Soil Type": "Well-drained sandy loam soil."
    },
    "Grapes": {
      "Sowing Time": "Spring (March to April)",
      "Harvesting Time": "Late summer to fall (August to October)",
      "Fertilizer Doses": "NPK: 150 kg N, 50 kg P, 100 kg K per hectare; Organic Matter: 10 tons of compost per hectare",
      "Irrigation": "Regular irrigation, especially during dry spells.",
      "Soil Type": "Well-drained, loamy soil with good organic matter."
    },
    "Mango": {
      "Sowing Time": "Spring to early summer",
      "Harvesting Time": "3-5 years after planting (varies by variety)",
      "Fertilizer Doses": "NPK: 150 kg N, 75 kg P, 75 kg K per hectare; Organic Matter: 10 tons of compost per hectare",
      "Irrigation": "Regular irrigation, especially during dry spells.",
      "Soil Type": "Well-drained sandy loam or loamy soil."
    },
    "Banana": {
      "Sowing Time": "All year round",
      "Harvesting Time": "9-12 months after planting",
      "Fertilizer Doses": "NPK: 150 kg N, 50 kg P, 100 kg K per hectare; Organic Matter: 10 tons of compost per hectare",
      "Irrigation": "Regular watering, especially during dry periods.",
      "Soil Type": "Well-drained, loamy soil."
    },
    "Pomegranate": {
      "Sowing Time": "Spring to early summer",
      "Harvesting Time": "6-7 months after flowering",
      "Fertilizer Doses": "NPK: 100 kg N, 50 kg P, 50 kg K per hectare; Organic Matter: 5-10 tons of compost per hectare",
      "Irrigation": "Regular watering, especially during dry spells.",
      "Soil Type": "Well-drained, sandy loam or loamy soil."
    },
    "Lentil": {
      "Sowing Time": "Fall (September to October)",
      "Harvesting Time": "90-110 days after sowing",
      "Fertilizer Doses": "NPK: 50 kg N, 20 kg P, 20 kg K per hectare; Organic Matter: 5 tons of compost per hectare",
      "Irrigation": "Minimal irrigation, mainly during dry spells.",
      "Soil Type": "Well-drained, loamy soil."
    },
    "Blackgram": {
      "Sowing Time": "Spring to early summer",
      "Harvesting Time": "60-70 days after sowing",
      "Fertilizer Doses": "NPK: 40 kg N, 20 kg P, 20 kg K per hectare; Organic Matter: 5 tons of compost per hectare",
      "Irrigation": "Minimal irrigation, mainly during dry spells.",
      "Soil Type": "Well-drained, loamy soil."
    },
    "Mungbean": {
      "Sowing Time": "Spring to early summer",
      "Harvesting Time": "60-70 days after sowing",
      "Fertilizer Doses": "NPK: 40 kg N, 20 kg P, 20 kg K per hectare; Organic Matter: 5 tons of compost per hectare",
      "Irrigation": "Minimal irrigation, mainly during dry spells.",
      "Soil Type": "Well-drained, loamy soil."
    },
    "Mothbeans": {
      "Sowing Time": "Spring to early summer",
      "Harvesting Time": "70-80 days after sowing",
      "Fertilizer Doses": "NPK: 30 kg N, 20 kg P, 20 kg K per hectare; Organic Matter: 5 tons of compost per hectare",
      "Irrigation": "Minimal irrigation, mainly during dry spells.",
      "Soil Type": "Well-drained, loamy soil."
    },
    "Pigeonpeas": {
      "Sowing Time": "Spring to early summer",
      "Harvesting Time": "6-9 months after sowing",
      "Fertilizer Doses": "NPK: 50 kg N, 20 kg P, 20 kg K per hectare; Organic Matter: 5 tons of compost per hectare",
      "Irrigation": "Minimal irrigation, mainly during dry spells.",
      "Soil Type": "Well-drained, loamy soil."
    },
    "Kidneybeans": {
      "Sowing Time": "Spring to early summer",
      "Harvesting Time": "90-100 days after sowing",
      "Fertilizer Doses": "NPK: 60 kg N, 30 kg P, 30 kg K per hectare; Organic Matter: 5 tons of compost per hectare",
      "Irrigation": "Regular irrigation during dry spells.",
      "Soil Type": "Well-drained, loamy soil."
    },
    "Chickpea": {
      "Sowing Time": "Fall (September to October)",
      "Harvesting Time": "90-100 days after sowing",
      "Fertilizer Doses": "NPK: 50 kg N, 30 kg P, 20 kg K per hectare; Organic Matter: 5 tons of compost per hectare",
      "Irrigation": "Minimal irrigation, mainly during dry spells.",
      "Soil Type": "Well-drained, loamy soil."
    },
    "Coffee": {
      "Sowing Time": "Spring to early summer",
      "Harvesting Time": "9-12 months after flowering",
      "Fertilizer Doses": "NPK: 150 kg N, 50 kg P, 100 kg K per hectare; Organic Matter: 10 tons of compost per hectare",
      "Irrigation": "Regular irrigation, especially during dry periods.",
      "Soil Type": "Well-drained, rich loamy soil."
    }
  }
  const [val, setVal] = React.useState({
    temperature: 25,
    humidity: 60,
    nitrogen: 61,
    potassium: 50,
    phosphorus: 50, 
    ph: 6.54,  
    rainfall: 100
});
// const [predicted, setPredictedData] = useState(false)

  const handleChange = (e) => {
    setVal({ ...val, [e.target.name]: e.target.value })
};

const handlePrediction = async () => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/v1/ai/recommendation_crop`,{
              "temperature": val.temperature,
              "humidity": val.humidity,
              "nitrogen": val.nitrogen,
              "potassium": val.potassium,
              "phosphorus": val.phosphorus,
              "ph": val.ph,
              "rainfall": val.rainfall
      }
    );
    if (response.data.status == 'success') {
      setCropRecommend(response.data)
    }

  } catch (error) {
    console.error('Error fetching API:', error);
  }
}

  let iscropRecommendReady = cropRecommend && cropRecommend.df_db_display && Array.isArray(cropRecommend.df_db_display);
  let iscropRecommendReady_device = cropRecommend && cropRecommend.predicted_crops_device;
  let iscropRecommendReady_user = cropRecommend && cropRecommend.predicted_crops_user;



  return (
    <Container fluid className="main-content-container px-4 pb-4" style={{ backgroundColor: "white" }}>
      {/* Page Header */}
      {/* <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Crop recommendation" subtitle="Recommendations" className="text-sm-left" />
      </Row> */}
     
      <Container fluid className="px-2 h-500">
      <RecommendationForm />
    </Container>
    </Container>
  );
}


export default AddNewPost;
