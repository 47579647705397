import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";

const recommendation = () => {
  const [val, setVal] = React.useState({
    temperature: 25,
    humidity: 50,
    nitrogen: 50,
    potassium: 50,
    phosphorus: 50,
    croptype: 1,
    soiltype: 1
  });
  const [predicted, setPredictedData] = useState("");

  const handlePrediction = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/agri/fertilizer/recommendation`,
      {
        temperature: val.temperature,
        humidity: val.humidity,
        nitrogen: val.nitrogen,
        potassium: val.potassium,
        phosphorous: val.phosphorus,
        croptype: val.croptype,
        soiltype: val.soiltype
      }
    );
    if (response.data.status == "success") {
      setPredictedData(response.data.data);
    }
  };
  const handleChange = e => {
    setVal({ ...val, [e.target.name]: e.target.value });
  };

  //   <div
  //   style={{
  //     width: "100%",
  //     marginTop: 20,
  //     marginBottom: 20,
  //     display: "flex",
  //     alignItems: "center",
  //     justifyContent: "center",
  //     flexDirection: "column"
  //   }}
  // >
  //   <Box sx={{ width: 650 }}>
  //     <Typography variant="h8">Temperature</Typography>
  //     <Slider
  //       marks={[
  //         {
  //           value: -10,
  //           label: "-10"
  //         },
  //         {
  //           value: 50,
  //           label: "50"
  //         }
  //       ]}
  //       step={1}
  //       value={val.temperature}
  //       name="temperature"
  //       valueLabelDisplay="auto"
  //       min={-10}
  //       max={50}
  //       onChange={e => handleChange(e)}
  //       sx={{
  //         "& .MuiSlider-thumb": {
  //           height: "1.5rem", // Thumb height
  //           width: "1.5rem", // Thumb width
  //           backgroundImage: "url(/thumb.svg)", // Custom image URL
  //           backgroundPosition: "center", // Center the image in the thumb
  //           "&:hover, &.Mui-focusVisible": {
  //             boxShadow: "none" // Remove the glow effect on hover/focus
  //           }
  //         },
  //         "& .MuiSlider-rail": {
  //           height: "1.5rem", // Rail height
  //           backgroundColor: "grey", // Non-slided portion color (rail)
  //           borderRadius: "0.75rem" // Rounded rail
  //         },
  //         "& .MuiSlider-track": {
  //           height: "1.5rem", // Track height
  //           borderRadius: "0.75rem", // Rounded track
  //           backgroundColor: "#0E2748" // Active portion color (track)
  //         }
  //       }}
  //     />
  //   </Box>
  //   <Box sx={{ width: 650 }}>
  //     <Typography variant="h8">Humidity</Typography>
  // <Slider
  //   marks={[
  //     {
  //       value: 0,
  //       label: "0"
  //     },
  //     {
  //       value: 100,
  //       label: "100"
  //     }
  //   ]}
  //   step={1}
  //   value={val.humidity}
  //   name="humidity"
  //   valueLabelDisplay="auto"
  //   min={0}
  //   max={100}
  //   onChange={e => handleChange(e)}
  // />
  //   </Box>
  //   {/* <Box sx={{ width: 650 }}>
  //           <Typography variant="h8">Moisture</Typography>
  //   <Slider
  //       marks={[
  //           {
  //               value: 0,
  //               label: '0',
  //           },
  //           {
  //               value: 100,
  //               label: '100',
  //           },
  //       ]}
  //       step={1}
  //       value={val.moisture}
  //       name="moisture"
  //       valueLabelDisplay="auto"
  //       min={0}
  //       max={100}
  //       onChange={(e) => handleChange(e)}
  //   />
  //       </Box> */}
  //   <Box sx={{ width: 650 }}>
  //     <Typography variant="h8">Nitrogen</Typography>
  // <Slider
  //   marks={[
  //     {
  //       value: 0,
  //       label: "0"
  //     },
  //     {
  //       value: 200,
  //       label: "100"
  //     }
  //   ]}
  //   step={1}
  //   value={val.nitrogen}
  //   name="nitrogen"
  //   valueLabelDisplay="auto"
  //   min={0}
  //   max={200}
  //   onChange={e => handleChange(e)}
  // />
  //   </Box>
  //   <Box sx={{ width: 650 }}>
  //     <Typography variant="h8">Potassium</Typography>
  // <Slider
  //   marks={[
  //     {
  //       value: 0,
  //       label: "0"
  //     },
  //     {
  //       value: 200,
  //       label: "200"
  //     }
  //   ]}
  //   step={1}
  //   value={val.potassium}
  //   name="potassium"
  //   valueLabelDisplay="auto"
  //   min={0}
  //   max={200}
  //   onChange={e => handleChange(e)}
  // />
  //   </Box>
  //   <Box sx={{ width: 650 }}>
  //     <Typography variant="h8">Phosphorus</Typography>
  // <Slider
  //   marks={[
  //     {
  //       value: 0,
  //       label: "0"
  //     },
  //     {
  //       value: 200,
  //       label: "200"
  //     }
  //   ]}
  //   step={1}
  //   value={val.phosphorus}
  //   valueLabelDisplay="auto"
  //   name="phosphorus"
  //   min={0}
  //   max={200}
  //   onChange={e => handleChange(e)}
  // />
  //   </Box>
  //   <Box sx={{ width: 650 }}>
  //     <FormControl
  //       margin="normal"
  //       variant="outlined"
  //       fullWidth={true}
  //       sx={{ gap: 6 }}
  //     >
  //       <InputLabel id="demo-select-small-label">Soil Type</InputLabel>
  //       <Select
  //         labelId="demo-select-small-label"
  //         id="demo-select-small"
  //         value={val.soiltype}
  //         name="soiltype"
  //         onChange={e => handleChange(e)}
  //       >
  //         <MenuItem value="1">Loamy</MenuItem>
  //         <MenuItem value="0">Sandy</MenuItem>
  //         <MenuItem value="4">Clayey</MenuItem>
  //         <MenuItem value="2">Black</MenuItem>
  //         <MenuItem value="3">Red</MenuItem>
  //       </Select>
  //     </FormControl>
  //   </Box>
  //   <Box sx={{ width: 650 }}>
  //     <FormControl
  //       margin="normal"
  //       variant="outlined"
  //       fullWidth={true}
  //       sx={{ gap: 6 }}
  //     >
  //       <InputLabel id="demo-select-small-label">Crop Type</InputLabel>
  //       <Select
  //         labelId="demo-select-small-label"
  //         id="demo-select-small"
  //         value={val.croptype}
  //         name="croptype"
  //         onChange={e => handleChange(e)}
  //       >
  //         <MenuItem value="1">Sugarcane</MenuItem>
  //         <MenuItem value="2">Cotton</MenuItem>
  //         <MenuItem value="7">Millets</MenuItem>
  //         <MenuItem value="4">Paddy</MenuItem>
  //         <MenuItem value="9">Pulses</MenuItem>
  //         <MenuItem value="6">Wheat</MenuItem>
  //         <MenuItem value="3">Tobacco</MenuItem>
  //         <MenuItem value="5">Barley</MenuItem>
  //         <MenuItem value="8">Oil seeds</MenuItem>
  //         <MenuItem value="10">Ground Nuts</MenuItem>
  //         <MenuItem value="0">Maize</MenuItem>
  //       </Select>
  //     </FormControl>
  //   </Box>
  //   <Button variant="contained" onClick={handlePrediction}>
  //     Predict
  //   </Button>
  //   {predicted && (
  //     <div style={{ fontSize: 35, fontWeight: "bolder" }}>
  //       N-P-K : {predicted}
  //     </div>
  //   )}
  // </div>
  return (
    <>
      <Grid container >
        <Grid item xs={12} >
          <Grid item style={{ marginTop: '3rem', marginBottom: '2.25rem'}}>

          <Typography
            style={{
              color: "rgba(0, 0, 0, 0.80)",
              fontFeatureSettings: "'ss01' on, 'cv01' on, 'cv11' on",
              fontFamily: "Inter, sans-serif",
              fontSize: "1.5rem",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "1.125rem"
            }}
          >
            Fertilizer Recommendation
          </Typography>
          </Grid>

        </Grid>
        <Grid
          item
          xs={3.87}
          style={{
            borderRadius: "0.75rem",
            marginBottom: "1.75rem",
            marginRight: "1rem",
            border: "1px solid rgba(245, 138, 32, 0.60)",
            background: "#FFF",
            padding: "1rem",
            boxShadow: `
    1px 1px 2px 0px rgba(255, 255, 255, 0.30) inset,
    -1px -1px 2px 0px rgba(239, 239, 239, 0.50) inset,
    -4px 4px 8px 0px rgba(239, 239, 239, 0.20),
    4px -4px 8px 0px rgba(239, 239, 239, 0.20),
    -4px -4px 8px 0px rgba(255, 255, 255, 0.90),
    4px 4px 10px 0px rgba(239, 239, 239, 0.90)
  `
          }}
        >
          <Grid container>
            <Grid item xs={12} style={{ paddingBottom: "1.25rem" }}>
              <Typography
                variant="h8"
                style={{
                  color: "#181818",
                  fontSize: "1.25rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal"
                }}
              >
                Temperature ( °C )
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Slider
                marks={[
                  {
                    value: -10,
                    label: "-10"
                  },
                  {
                    value: val.temperature,
                    label: `${val.temperature}` // Current value label
                  },
                  {
                    value: 50,
                    label: "50"
                  }
                ]}
                step={1}
                value={val.temperature}
                name="temperature"
                valueLabelDisplay="off"
                min={-10}
                max={50}
                onChange={e => handleChange(e)}
                sx={{
                  "& .MuiSlider-thumb": {
                    height: "1.5rem", // Thumb height
                    width: "1.5rem", // Thumb width
                    backgroundImage: "url(/thumb.svg)", // Custom image URL
                    backgroundPosition: "center", // Center the image in the thumb
                    "&:hover, &.Mui-focusVisible": {
                      boxShadow: "none" // Remove the glow effect on hover/focus
                    }
                  },
                  "& .MuiSlider-rail": {
                    height: "1.5rem", // Rail height
                    backgroundColor: "grey", // Non-slided portion color (rail)
                    borderRadius: "0.75rem" // Rounded rail
                  },
                  "& .MuiSlider-track": {
                    height: "1.5rem", // Track height
                    borderRadius: "0.75rem", // Rounded track
                    backgroundColor: "#0E2748" // Active portion color (track)
                  },
                  "& .MuiSlider-markLabel[data-index='0']": {
                    transform: "translateY(6px) translateX(0px)" // Customize min label position
                  },
                  "& .MuiSlider-markLabel[data-index='1']": {
                    transform: "translateY(6px) translateX(-10px)" // Customize max label position
                  },
                  "& .MuiSlider-markLabel[data-index='2']": {
                    transform: "translateY(6px) translateX(-15px)" // Customize max label position
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={3.87}
          style={{
            marginBottom: "1.75rem",
            marginRight: "1rem",
            borderRadius: "0.75rem",
            border: "1px solid rgba(245, 138, 32, 0.60)",
            background: "#FFF",
            padding: "1rem",
            boxShadow: `
    1px 1px 2px 0px rgba(255, 255, 255, 0.30) inset,
    -1px -1px 2px 0px rgba(239, 239, 239, 0.50) inset,
    -4px 4px 8px 0px rgba(239, 239, 239, 0.20),
    4px -4px 8px 0px rgba(239, 239, 239, 0.20),
    -4px -4px 8px 0px rgba(255, 255, 255, 0.90),
    4px 4px 10px 0px rgba(239, 239, 239, 0.90)
  `
          }}
        >
          <Grid container>
            <Grid item xs={12} style={{ paddingBottom: "1.25rem" }}>
              <Typography
                variant="h8"
                style={{
                  color: "#181818",
                  fontSize: "1.25rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal"
                }}
              >
                Humidity ( %RH )
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Slider
                marks={[
                  {
                    value: 0,
                    label: "0"
                  },
                  {
                    value: val.humidity,
                    label: `${val.humidity}` // Current value label
                  },
                  {
                    value: 100,
                    label: "100"
                  }
                ]}
                step={1}
                value={val.humidity}
                name="humidity"
                valueLabelDisplay="off"
                min={0}
                max={100}
                onChange={e => handleChange(e)}
                sx={{
                  "& .MuiSlider-thumb": {
                    height: "1.5rem", // Thumb height
                    width: "1.5rem", // Thumb width
                    backgroundImage: "url(/thumb.svg)", // Custom image URL
                    backgroundPosition: "center", // Center the image in the thumb
                    "&:hover, &.Mui-focusVisible": {
                      boxShadow: "none" // Remove the glow effect on hover/focus
                    }
                  },
                  "& .MuiSlider-rail": {
                    height: "1.5rem", // Rail height
                    backgroundColor: "grey", // Non-slided portion color (rail)
                    borderRadius: "0.75rem" // Rounded rail
                  },
                  "& .MuiSlider-track": {
                    height: "1.5rem", // Track height
                    borderRadius: "0.75rem", // Rounded track
                    backgroundColor: "#0E2748" // Active portion color (track)
                  },
                  "& .MuiSlider-markLabel[data-index='0']": {
                    transform: "translateY(6px) translateX(0px)" // Customize min label position
                  },
                  "& .MuiSlider-markLabel[data-index='1']": {
                    transform: "translateY(6px) translateX(-15px)" // Customize max label position
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={3.87}
          style={{
            marginBottom: "1.75rem",
            borderRadius: "0.75rem",
            border: "1px solid rgba(245, 138, 32, 0.60)",
            background: "#FFF",
            padding: "1rem",
            boxShadow: `
    1px 1px 2px 0px rgba(255, 255, 255, 0.30) inset,
    -1px -1px 2px 0px rgba(239, 239, 239, 0.50) inset,
    -4px 4px 8px 0px rgba(239, 239, 239, 0.20),
    4px -4px 8px 0px rgba(239, 239, 239, 0.20),
    -4px -4px 8px 0px rgba(255, 255, 255, 0.90),
    4px 4px 10px 0px rgba(239, 239, 239, 0.90)
  `
          }}
        >
          <Grid container>
            <Grid item xs={12} style={{ paddingBottom: "1.25rem" }}>
              <Typography
                variant="h8"
                style={{
                  color: "#181818",
                  fontSize: "1.25rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal"
                }}
              >
                Moisture
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Slider
                marks={[
                  {
                    value: 0,
                    label: "0"
                  },
                  {
                    value: val.moisture,
                    label: `${val.moisture}` // Current value label
                  },
                  {
                    value: 100,
                    label: "100"
                  }
                ]}
                step={1}
                value={val.moisture}
                name="moisture"
                valueLabelDisplay="off"
                min={0}
                max={100}
                onChange={e => handleChange(e)}
                sx={{
                  "& .MuiSlider-thumb": {
                    height: "1.5rem", // Thumb height
                    width: "1.5rem", // Thumb width
                    backgroundImage: "url(/thumb.svg)", // Custom image URL
                    backgroundPosition: "center", // Center the image in the thumb
                    "&:hover, &.Mui-focusVisible": {
                      boxShadow: "none" // Remove the glow effect on hover/focus
                    }
                  },
                  "& .MuiSlider-rail": {
                    height: "1.5rem", // Rail height
                    backgroundColor: "grey", // Non-slided portion color (rail)
                    borderRadius: "0.75rem" // Rounded rail
                  },
                  "& .MuiSlider-track": {
                    height: "1.5rem", // Track height
                    borderRadius: "0.75rem", // Rounded track
                    backgroundColor: "#0E2748" // Active portion color (track)
                  },
                  "& .MuiSlider-markLabel[data-index='0']": {
                    transform: "translateY(6px) translateX(0px)" // Customize min label position
                  },
                  "& .MuiSlider-markLabel[data-index='1']": {
                    transform: "translateY(6px) translateX(-15px)" // Customize max label position
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={3.87}
          style={{
            borderRadius: "0.75rem",
            marginRight: "1rem",
            border: "1px solid rgba(245, 138, 32, 0.60)",
            background: "#FFF",
            padding: "1rem",
            boxShadow: `
    1px 1px 2px 0px rgba(255, 255, 255, 0.30) inset,
    -1px -1px 2px 0px rgba(239, 239, 239, 0.50) inset,
    -4px 4px 8px 0px rgba(239, 239, 239, 0.20),
    4px -4px 8px 0px rgba(239, 239, 239, 0.20),
    -4px -4px 8px 0px rgba(255, 255, 255, 0.90),
    4px 4px 10px 0px rgba(239, 239, 239, 0.90)
  `
          }}
        >
          <Grid container>
            <Grid item xs={12} style={{ paddingBottom: "1.25rem" }}>
              <Typography
                variant="h8"
                style={{
                  color: "#181818",
                  fontSize: "1.25rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal"
                }}
              >
                Nitrogen ( mg/kg )
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Slider
                marks={[
                  {
                    value: 0,
                    label: "0"
                  },
                  {
                    value: val.nitrogen,
                    label: `${val.nitrogen}` // Current value label
                  },
                  {
                    value: 200,
                    label: "100"
                  }
                ]}
                step={1}
                value={val.nitrogen}
                name="nitrogen"
                valueLabelDisplay="off"
                min={0}
                max={200}
                onChange={e => handleChange(e)}
                sx={{
                  "& .MuiSlider-thumb": {
                    height: "1.5rem", // Thumb height
                    width: "1.5rem", // Thumb width
                    backgroundImage: "url(/thumb.svg)", // Custom image URL
                    backgroundPosition: "center", // Center the image in the thumb
                    "&:hover, &.Mui-focusVisible": {
                      boxShadow: "none" // Remove the glow effect on hover/focus
                    }
                  },
                  "& .MuiSlider-rail": {
                    height: "1.5rem", // Rail height
                    backgroundColor: "grey", // Non-slided portion color (rail)
                    borderRadius: "0.75rem" // Rounded rail
                  },
                  "& .MuiSlider-track": {
                    height: "1.5rem", // Track height
                    borderRadius: "0.75rem", // Rounded track
                    backgroundColor: "#0E2748" // Active portion color (track)
                  },
                  "& .MuiSlider-markLabel[data-index='0']": {
                    transform: "translateY(6px) translateX(0px)" // Customize min label position
                  },
                  "& .MuiSlider-markLabel[data-index='1']": {
                    transform: "translateY(6px) translateX(-15px)" // Customize max label position
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={3.87}
          style={{
            borderRadius: "0.75rem",
            marginRight: "1rem",
            border: "1px solid rgba(245, 138, 32, 0.60)",
            background: "#FFF",
            padding: "1rem",
            boxShadow: `
    1px 1px 2px 0px rgba(255, 255, 255, 0.30) inset,
    -1px -1px 2px 0px rgba(239, 239, 239, 0.50) inset,
    -4px 4px 8px 0px rgba(239, 239, 239, 0.20),
    4px -4px 8px 0px rgba(239, 239, 239, 0.20),
    -4px -4px 8px 0px rgba(255, 255, 255, 0.90),
    4px 4px 10px 0px rgba(239, 239, 239, 0.90)
  `
          }}
        >
          <Grid container>
            <Grid item xs={12} style={{ paddingBottom: "1.25rem" }}>
              <Typography
                variant="h8"
                style={{
                  color: "#181818",
                  fontSize: "1.25rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal"
                }}
              >
                Potassium ( mg/kg )
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Slider
                marks={[
                  {
                    value: 0,
                    label: "0"
                  },
                  {
                    value: val.potassium,
                    label: `${val.potassium}` // Current value label
                  },
                  {
                    value: 200,
                    label: "200"
                  }
                ]}
                step={1}
                value={val.potassium}
                name="potassium"
                valueLabelDisplay="off"
                min={0}
                max={200}
                onChange={e => handleChange(e)}
                sx={{
                  "& .MuiSlider-thumb": {
                    height: "1.5rem", // Thumb height
                    width: "1.5rem", // Thumb width
                    backgroundImage: "url(/thumb.svg)", // Custom image URL
                    backgroundPosition: "center", // Center the image in the thumb
                    "&:hover, &.Mui-focusVisible": {
                      boxShadow: "none" // Remove the glow effect on hover/focus
                    }
                  },
                  "& .MuiSlider-rail": {
                    height: "1.5rem", // Rail height
                    backgroundColor: "grey", // Non-slided portion color (rail)
                    borderRadius: "0.75rem" // Rounded rail
                  },
                  "& .MuiSlider-track": {
                    height: "1.5rem", // Track height
                    borderRadius: "0.75rem", // Rounded track
                    backgroundColor: "#0E2748" // Active portion color (track)
                  },
                  "& .MuiSlider-markLabel[data-index='0']": {
                    transform: "translateY(6px) translateX(0px)" // Customize min label position
                  },
                  "& .MuiSlider-markLabel[data-index='1']": {
                    transform: "translateY(6px) translateX(-15px)" // Customize max label position
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={3.87}
          style={{
            borderRadius: "0.75rem",
            border: "1px solid rgba(245, 138, 32, 0.60)",
            background: "#FFF",
            padding: "1rem",
            boxShadow: `
    1px 1px 2px 0px rgba(255, 255, 255, 0.30) inset,
    -1px -1px 2px 0px rgba(239, 239, 239, 0.50) inset,
    -4px 4px 8px 0px rgba(239, 239, 239, 0.20),
    4px -4px 8px 0px rgba(239, 239, 239, 0.20),
    -4px -4px 8px 0px rgba(255, 255, 255, 0.90),
    4px 4px 10px 0px rgba(239, 239, 239, 0.90)
  `
          }}
        >
          <Grid container>
            <Grid item xs={12} style={{ paddingBottom: "1.25rem" }}>
              <Typography
                variant="h8"
                style={{
                  color: "#181818",
                  fontSize: "1.25rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal"
                }}
              >
                Phosphorus ( mg/kg )
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Slider
                marks={[
                  {
                    value: 0,
                    label: "0"
                  },
                  {
                    value: val.phosphorus,
                    label: `${val.phosphorus}` // Current value label
                  },
                  {
                    value: 200,
                    label: "200"
                  }
                ]}
                step={1}
                value={val.phosphorus}
                valueLabelDisplay="off"
                name="phosphorus"
                min={0}
                max={200}
                onChange={e => handleChange(e)}
                sx={{
                  "& .MuiSlider-thumb": {
                    height: "1.5rem", // Thumb height
                    width: "1.5rem", // Thumb width
                    backgroundImage: "url(/thumb.svg)", // Custom image URL
                    backgroundPosition: "center", // Center the image in the thumb
                    "&:hover, &.Mui-focusVisible": {
                      boxShadow: "none" // Remove the glow effect on hover/focus
                    }
                  },
                  "& .MuiSlider-rail": {
                    height: "1.5rem", // Rail height
                    backgroundColor: "grey", // Non-slided portion color (rail)
                    borderRadius: "0.75rem" // Rounded rail
                  },
                  "& .MuiSlider-track": {
                    height: "1.5rem", // Track height
                    borderRadius: "0.75rem", // Rounded track
                    backgroundColor: "#0E2748" // Active portion color (track)
                  },
                  "& .MuiSlider-markLabel[data-index='0']": {
                    transform: "translateY(6px) translateX(0px)" // Customize min label position
                  },
                  "& .MuiSlider-markLabel[data-index='1']": {
                    transform: "translateY(6px) translateX(-15px)" // Customize max label position
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "2rem" }}>
        <Grid item xs={4}>
          <Grid item xs={12} style={{ marginBottom: "0.5rem" }}>
            <Typography
              style={{
                color: "rgba(0, 0, 0, 0.80)",
                fontSize: "1.25rem",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal"
              }}
            >
              Soil Type
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={val.soiltype}
              name="soiltype"
              style={{ width: "20rem", height: "4.5rem" }}
              onChange={e => handleChange(e)}
            >
              <MenuItem value="1">Loamy</MenuItem>
              <MenuItem value="0">Sandy</MenuItem>
              <MenuItem value="4">Clayey</MenuItem>
              <MenuItem value="2">Black</MenuItem>
              <MenuItem value="3">Red</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid item xs={12} style={{ marginBottom: "0.5rem" }}>
            <Typography
              style={{
                color: "rgba(0, 0, 0, 0.80)",
                fontSize: "1.25rem",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal"
              }}
            >
              Crop Type
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={val.croptype}
              name="croptype"
              style={{ width: "20rem", height: "4.5rem" }}
              onChange={e => handleChange(e)}
            >
              <MenuItem value="1">Sugarcane</MenuItem>
              <MenuItem value="2">Cotton</MenuItem>
              <MenuItem value="7">Millets</MenuItem>
              <MenuItem value="4">Paddy</MenuItem>
              <MenuItem value="9">Pulses</MenuItem>
              <MenuItem value="6">Wheat</MenuItem>
              <MenuItem value="3">Tobacco</MenuItem>
              <MenuItem value="5">Barley</MenuItem>
              <MenuItem value="8">Oil seeds</MenuItem>
              <MenuItem value="10">Ground Nuts</MenuItem>
              <MenuItem value="0">Maize</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "2.13rem" }}>
          <Button
            variant="contained"
            onClick={handlePrediction}
            style={{
              display: "flex",
              width: "8.75rem",
              padding: "1rem 1.5rem",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.25rem",
              borderRadius: "0.5rem",
              background: "#006BE0"
            }}
          >
            Predict
          </Button>
        </Grid>
        {predicted && (
          <>
            <Grid
              item
              xs={12}
              style={{
                marginTop: "3.5rem",
                alignItems: "center",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Typography
                style={{
                  color: "rgba(0, 0, 0, 0.80)",
                  fontSize: "1.5rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal"
                }}
              >
                Prediction:
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Typography
                style={{
                  color: "#FFFFFF",
                  fontFamily: "Inter",
                  fontSize: "1.5rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  padding: "0.75rem 1.5rem",
                  borderRadius: "0.75rem",
                  border: "4px solid #F58A20",
                  background: "#0E2748"
                }}
              >
                N-P-K : {predicted}
              </Typography>
            </Grid>
          </>
        )}
        {/* {predicted && (
          <div style={{ fontSize: 35, fontWeight: "bolder" }}>
            N-P-K : {predicted}
          </div>
        )} */}
      </Grid>
    </>
  );
};

export default recommendation;
