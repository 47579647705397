import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import shortid from "shortid";
import { Card, CardBody } from "shards-react";

import Chart from "../../utils/chart";
import { Grid, Typography } from "@mui/material";

class SmallStats extends React.Component {
  constructor(props) {
    super(props);

    // this.canvasRef = React.createRef();
  }

  // componentDidMount() {

  //   if (!this.canvasRef.current) return;
  //   const chartOptions = {
  //     ...{
  //       maintainAspectRatio: true,
  //       responsive: true,
  //       legend: {
  //         display: false
  //       },
  //       tooltips: {
  //         enabled: false,
  //         custom: false
  //       },
  //       elements: {
  //         point: {
  //           radius: 0
  //         },
  //         line: {
  //           tension: 0.33
  //         }
  //       },
  //       scales: {
  //         xAxes: [
  //           {
  //             gridLines: false,
  //             ticks: {
  //               display: false
  //             }
  //           }
  //         ],
  //         yAxes: [
  //           {
  //             gridLines: false,
  //             scaleLabel: false,
  //             ticks: {
  //               display: false,
  //               isplay: false,
  //               // Avoid getting the graph line cut of at the top of the canvas.
  //               // Chart.js bug link: https://github.com/chartjs/Chart.js/issues/4790
  //               suggestedMax: Math.max(...this.props.chartData[0].data) + 1
  //             }
  //           }
  //         ]
  //       }
  //     },
  //     ...this.props.chartOptions
  //   };

  //   const chartConfig = {
  //     ...{
  //       type: "line",
  //       data: {
  //         ...{
  //           labels: this.props.chartLabels
  //         },
  //         ...{
  //           datasets: this.props.chartData
  //         }
  //       },
  //       options: chartOptions
  //     },
  //     ...this.props.chartConfig
  //   };

  //   new Chart(this.canvasRef.current, chartConfig);
  // }

  render() {
    // const { variation, label, value, percentage, increase } = this.props;

    // const cardClasses = classNames(
    //   "stats-small",
    //   variation && `stats-small--${variation}`
    // );

    // const cardBodyClasses = classNames(
    //   variation === "1" ? "p-0 d-flex" : "px-0 pb-0"
    // );

    // const innerWrapperClasses = classNames(
    //   "d-flex",
    //   variation === "1" ? "flex-column m-auto" : "px-3"
    // );

    // const dataFieldClasses = classNames(
    //   "stats-small__data",
    //   variation === "1" && "text-center"
    // );

    // const labelClasses = classNames(
    //   "stats-small__label",
    //   "text-uppercase",
    //   variation !== "1" && "mb-1"
    // );

    // const valueClasses = classNames(
    //   "stats-small__value",
    //   "count",
    //   variation === "1" ? "my-3" : "m-0"
    // );

    // const innerDataFieldClasses = classNames(
    //   "stats-small__data",
    //   variation !== "1" && "text-right align-items-center"
    // );

    // const percentageClasses = classNames(
    //   "stats-small__percentage",
    //   `stats-small__percentage--${increase ? "increase" : "decrease"}`
    // );

    // const canvasHeight = variation === "1" ? 120 : 60;

    return (
      <>
        {/* <Card small className={cardClasses}>
         <CardBody className={cardBodyClasses}>
           <div className={innerWrapperClasses}>
             <div className={dataFieldClasses}>
               <span className={labelClasses}>{label}</span>
               <h6 className={valueClasses}>{value}</h6>
             </div>
             <div className={innerDataFieldClasses}>
               <span className={percentageClasses}>{percentage}</span>
             </div>
           </div>
           <canvas
            height={canvasHeight}
            ref={this.canvasRef}
            className={`stats-small-${shortid()}`}
          />
        </CardBody>
      </Card> */}

        <Grid
          container
          style={{
            borderRadius: "0.75rem",
            border: "1px solid rgba(0, 0, 0, 0.10)",
            background: "#FFF",
            padding: "1.25rem 0.5rem",
          }}
        >
          <Grid item xs={7} style={{ marginBottom: "1.13rem", paddingRight: '2.8rem'}}>
            <Grid container>
              <Grid item xs={12} style={{ marginBottom: "1.58rem" }}>
                <Typography
                  style={{
                    color: "rgba(0, 0, 0, 0.80)",
                    fontFeatureSettings: "'ss01' on, 'cv01' on, 'cv11' on",
                    fontSize: "0.875rem",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "1.125rem"
                  }}
                >
                  {this.props.label}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  style={{
                    color: "#000",
                    fontFeatureSettings: "'ss01' on, 'cv01' on, 'cv11' on",
                    fontSize: "1.5rem",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "1.125rem",
                    letterSpacing: "0.015rem"
                  }}
                >
                  {this.props.value}
                  <span
                    style={{
                      color: "rgba(0, 0, 0, 0.70)",
                      fontFeatureSettings: "'ss01' on, 'cv01' on, 'cv11' on",
                      fontSize: "1rem",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "1.125rem",
                      letterSpacing: "0.01rem"
                    }}
                  >
                    {this.props.units}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5} style={{ marginBottom: "1.13rem", textAlign: 'right' }}>
                <img src={this.props.img} style={{ width: '3.175rem', height: '3.175rem'}}/>
 
              
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{
                color: "rgba(0, 0, 0, 0.80)",
                fontFeatureSettings: "'ss01' on, 'cv01' on, 'cv11' on",
                fontSize: "0.75rem",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "1.125rem"
              }}
            >
                {this.props.ts}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }
}

// SmallStats.propTypes = {
//   /**
//    * The Small Stats variation.
//    */
//   variation: PropTypes.string,
//   /**
//    * The label.
//    */
//   label: PropTypes.string,
//   /**
//    * The value.
//    */
//   value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//   /**
//    * The percentage number or string.
//    */
//   percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//   /**
//    * Whether is a value increase, or not.
//    */
//   increase: PropTypes.bool,
//   /**
//    * The Chart.js configuration object.
//    */
//   chartConfig: PropTypes.object,
//   /**
//    * The Chart.js options object.
//    */
//   chartOptions: PropTypes.object,
//   /**
//    * The chart data.
//    */
//   chartData: PropTypes.array.isRequired,
//   /**
//    * The chart labels.
//    */
//   chartLabels: PropTypes.array
// };

// SmallStats.defaultProps = {
//   increase: true,
//   percentage: 0,
//   value: 0,
//   label: "Label",
//   chartOptions: Object.create(null),
//   chartConfig: Object.create(null),
//   chartData: [],
//   chartLabels: []
// };

export default SmallStats;
