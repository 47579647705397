import {
  Box,
  Button,
  Grid,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Input,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Plot from "react-plotly.js";

const NutrientCalculator = () => {
  const [data, setLatest] = useState({});
  const [zone, setZone] = useState("Western Himalayas (J & K, H.P.)");
  const [soil, setSoil] = useState();
  const [crop, setCrop] = useState();
  const [input, setInput] = useState("1.0");
  const [npk, setNpk] = useState();
  const [predict, setPredict] = useState();
  const [currsoil, setCurrSoil] = useState();
  const [currcrop, setCurrCrop] = useState();
  const [currnpk, setCurrNpk] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/get/soil/nutrient/calculator`
        );
        if (response.data.status == "success") {
          console.log(response.data);

          setLatest(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_BASE_URL
          }/get/zone/soil/nutrient/calculator?zone=${encodeURIComponent(zone)}`
        );
        if (response.data.status == "success") {
          setSoil(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [zone]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_BASE_URL
          }/get/zone/crop/nutrient/calculator?zone=${encodeURIComponent(
            zone
          )}&soil=${encodeURIComponent(currsoil)}`
        );
        if (response.data.status == "success") {
          setCrop(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [currsoil]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_BASE_URL
          }/get/zone/npk/nutrient/calculator?zone=${encodeURIComponent(
            zone
          )}&soil=${encodeURIComponent(currsoil)}&crop=${encodeURIComponent(
            currcrop
          )}`
        );
        if (response.data.status == "success") {
          setNpk(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [currcrop]);

  const handlePredict = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_BASE_URL
        }/get/zone/predict/nutrient/calculator?zone=${encodeURIComponent(
          zone
        )}&soil=${encodeURIComponent(currsoil)}&crop=${encodeURIComponent(
          currcrop
        )}&npk=${encodeURIComponent(currnpk)}&hectares=${encodeURIComponent(
          input
        )}`
      );
      if (response.data.status == "success") {
        setPredict(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#334474" }}>{"SL. No"}</strong>
      ),
      filterable: false,
      renderCell: params => params.api.getAllRowIds().indexOf(params.id) + 1
    },
    {
      field: "humidity",
      headerName: "Humidity",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#334474" }}>{"Humidity"}</strong>
      )
    },
    {
      field: "ph",
      headerName: "ph",
      width: 80,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ color: "#334474" }}>{"pH"}</strong>
    },
    {
      field: "potassium",
      headerName: "Potassium",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#334474" }}>{"Potassium"}</strong>
      )
    },
    {
      field: "temperature",
      headerName: "temperature",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#334474" }}>{"Temperature"}</strong>
      )
    },
    {
      field: "timestamp",
      headerName: "timestamp",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"Timestamp"}</strong>
      )
    },
    {
      field: "conductivity",
      headerName: "conductivity",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"Conductivity"}</strong>
      )
    },
    {
      field: "nitrogen",
      headerName: "nitrogen",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"Nitrogen"}</strong>
      )
    },
    {
      field: "phosphorus",
      headerName: "phosphorus",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"Phosphorus"}</strong>
      )
    }
  ];

  const columnsNutrientTable = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#334474" }}>{"SL. No"}</strong>
      ),
      filterable: false,
      renderCell: params => params.api.getAllRowIds().indexOf(params.id) + 1
    },
    {
      field: "Nutrient",
      headerName: "Nutrient",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#334474" }}>{"Nutrient"}</strong>
      )
    },
    {
      field: "Current",
      headerName: "Current",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#334474" }}>{"Current"}</strong>
      )
    },
    {
      field: "Recommended",
      headerName: "Recommended",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#334474" }}>{"Recommended"}</strong>
      )
    },
    {
      field: "Required",
      headerName: "Required",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#334474" }}>{"Required"}</strong>
      )
    },
    {
      field: "Excess",
      headerName: "Excess",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ color: "#57926e" }}>{"Excess"}</strong>
      )
    }
  ];

  const islatestDataReady = data && data.datass && Array.isArray(data.datass);

  const isagroclimaticzonesReady =
    data && data.agroclimatic_zones && Array.isArray(data.agroclimatic_zones);
  const isSoilReady = soil && soil.soils && Array.isArray(soil.soils);
  const isPredictReady =
    predict &&
    predict.recommended_nutrients &&
    predict.required_nutrients &&
    predict.excess_nutrients &&
    Array.isArray(predict.recommended_nutrients) &&
    Array.isArray(predict.excess_nutrients) &&
    Array.isArray(predict.required_nutrients) &&
    predict.current_values &&
    Array.isArray(predict.current_values) &&
    predict.total_required_nutrients &&
    Array.isArray(predict.total_required_nutrients) &&
    predict.total_excess_nutrients &&
    Array.isArray(predict.total_excess_nutrients) &&
    predict.recommendations &&
    Array.isArray(predict.recommendations);
  let current_values;
  let recommended_nutrients;
  if (isPredictReady) {
    current_values = predict.current_values;
    recommended_nutrients = predict.recommended_nutrients;
  }

  return (
    <Container
      fluid
      className="main-content-container px-4 py-4"
      style={{ backgroundColor: "white" }}
    >
      {/* <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Nutrient Calculator"
          className="text-sm-left"
        />
      </Row> */}
      <Grid container spacing={2.5}>
                <Grid item xs={12} >
                  <Grid item style={{ marginTop: '3rem', marginBottom: '2.25rem'}}>
        
                  <Typography
                    style={{
                      color: "rgba(0, 0, 0, 0.80)",
                      fontFeatureSettings: "'ss01' on, 'cv01' on, 'cv11' on",
                      fontFamily: "Inter, sans-serif",
                      fontSize: "1.5rem",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "1.125rem"
                    }}
                  >
                    Nutrient Calculator
                  </Typography>
                  </Grid>
        
                </Grid>
        {islatestDataReady ? (
          <Grid item xs={12}>
            <DataGrid
              getRowId={data => data.ph + data.timestamp}
              rows={data.datass}
              columns={columns}
              density="compact"
              rowCount={100}
              paginationMode="server"
              components={{ Toolbar: GridToolbar }}
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            loading latestData...
          </Grid>
        )}
        {isagroclimaticzonesReady && (
          <Grid item xs={12} sm={6} md={4} lg={3} style={{ marginTop: "1rem" }}>
            <Grid item xs={12} style={{ marginBottom: "0.5rem" }}>
              <Typography
                style={{
                  color: "rgba(0, 0, 0, 0.80)",
                  fontSize: "1.25rem",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal"
                }}
              >
                Agroclimatic Zone
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Select
                style={{ minWidth: "100%" }}
                id="AgroclimaticZone"
                value={zone}
                onChange={e => setZone(e.target.value)}
              >
                {data.agroclimatic_zones.map((zone, index) => (
                  <MenuItem index={index} value={zone} name={zone}>
                    {zone}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        )}
        {isagroclimaticzonesReady && (
          <Grid item xs={12} sm={6} md={4} lg={3} style={{ marginTop: "1rem" }}>
            <Grid item xs={12} style={{ marginBottom: "0.5rem" }}>
              <Typography
                style={{
                  color: "rgba(0, 0, 0, 0.80)",
                  fontSize: "1.25rem",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal"
                }}
              >
                Select Soil
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Select
                id="Select Soil"
                style={{ minWidth: "100%" }}
                value={currsoil}
                onChange={e => setCurrSoil(e.target.value)}
              >
                {soil.soils.map((soil, index) => (
                  <MenuItem index={index} value={soil} name={soil}>
                    {soil}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        )}
        {isagroclimaticzonesReady && (
          <Grid item xs={12} sm={6} md={4} lg={3} style={{ marginTop: "1rem" }}>
            <Grid item xs={12} style={{ marginBottom: "0.5rem" }}>
              <Typography
                style={{
                  color: "rgba(0, 0, 0, 0.80)",
                  fontSize: "1.25rem",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal"
                }}
              >
                Select Cropping System
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Select
                labelId="asset-select-label"
                id="Select Crop/Cropping System"
                value={currcrop}
                style={{ minWidth: "100%" }}
                onChange={e => setCurrCrop(e.target.value)}
              >
                {crop.crops.map((crop, index) => (
                  <MenuItem index={index} value={crop} name={crop}>
                    {crop}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        )}
        {isagroclimaticzonesReady && (
          <Grid item xs={12} sm={6} md={4} lg={3} style={{ marginTop: "1rem" }}>
            <Grid item xs={12} style={{ marginBottom: "0.5rem" }}>
              <Typography
                style={{
                  color: "rgba(0, 0, 0, 0.80)",
                  fontSize: "1.25rem",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal"
                }}
              >
                Select NPK Ratio
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Select
                labelId="asset-select-label"
                id="Select NPK Ratio"
                style={{ minWidth: "100%" }}
                value={currnpk}
                onChange={e => setCurrNpk(e.target.value)}
              >
                {npk.npk_ratios.map((npk, index) => (
                  <MenuItem index={index} value={npk} name={npk}>
                    {npk}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Grid item xs={12} style={{ marginBottom: "0.5rem" }}>
            <Typography
              style={{
                color: "rgba(0, 0, 0, 0.80)",
                fontSize: "1.25rem",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal"
              }}
            >
              Enter Hectares of Land
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Input
              labelId="asset-select-label"
              step="any"
              fullWidth={true}
              value={input}
              onChange={e => setInput(e.target.value)}
            />
          </Grid>
        </Grid>
        {currnpk && (
          <Grid item xs={12} style={{ marginTop: "2.13rem" }}>
            <Button
              variant="contained"
              onClick={handlePredict}
              style={{
                display: "flex",
                padding: "1rem 1.5rem",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.25rem",
                borderRadius: "0.5rem",
                background: "#006BE0"
              }}
            >
              Calculate Nutrients
            </Button>
          </Grid>
        )}
        {isPredictReady && (
          <Grid item xs={12} style={{ marginTop: "1.25rem" }}>
            <Typography
              style={{
                color: "rgba(0, 0, 0, 0.80)",
                fontSize: "1rem",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal"
              }}
            >
              Nutrient Calculation
            </Typography>
          </Grid>
        )}
        {isPredictReady && (
          <Grid item xs={12} style={{ marginTop: "0.5rem" }}>
            <Box
              sx={{
                position: "relative",
                display: "inline-block",
                border: "1px solid #828282",
                borderRadius: "0.25rem",
                overflow: "hidden"
              }}
            >
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        backgroundColor: "#EBEBEB",
                        gap: "0.625rem",
                        borderRight: "1px solid #828282",
                        borderBottom: "1px solid #828282"
                      }}
                    >
                      Current Nutrients
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem",
                        borderBottom: "1px solid #828282"
                      }}
                    >
                      Nitrogen: {predict.current_values[0]}
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem",
                        borderBottom: "1px solid #828282"
                      }}
                    >
                      Phosphorus: {predict.current_values[1]}
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem",
                        borderBottom: "1px solid #828282"
                      }}
                    >
                      Potassium: {predict.current_values[2]}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem",
                        borderBottom: "1px solid #828282",
                        backgroundColor: "#EBEBEB",
                        borderRight: "1px solid #828282"
                      }}
                    >
                      Recommended Nutrients
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem",
                        borderBottom: "1px solid #828282"
                      }}
                    >
                      Nitrogen: {predict.recommended_nutrients[0]}
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem",
                        borderBottom: "1px solid #828282"
                      }}
                    >
                      Phosphorus: {predict.recommended_nutrients[1]}
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem",
                        borderBottom: "1px solid #828282"
                      }}
                    >
                      Potassium: {predict.recommended_nutrients[2]}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem",
                        borderBottom: "1px solid #828282",
                        backgroundColor: "#EBEBEB",
                        borderRight: "1px solid #828282"
                      }}
                    >
                      Required Nutrients
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem",
                        borderBottom: "1px solid #828282"
                      }}
                    >
                      Nitrogen: {predict.required_nutrients[0]}
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem",
                        borderBottom: "1px solid #828282"
                      }}
                    >
                      Phosphorus: {predict.required_nutrients[1]}
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem",
                        borderBottom: "1px solid #828282"
                      }}
                    >
                      Potassium: {predict.required_nutrients[2]}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem",
                        backgroundColor: "#EBEBEB",
                        borderRight: "1px solid #828282"
                      }}
                    >
                      Excess Nutrients
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem"
                      }}
                    >
                      Nitrogen: {predict.excess_nutrients[0]}
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem"
                      }}
                    >
                      Phosphorus: {predict.excess_nutrients[1]}
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem"
                      }}
                    >
                      Potassium: {predict.excess_nutrients[2]}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Grid>
        )}
        {isPredictReady && (
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Plot
              data={[
                {
                  x: ["Nitrogen", "Phosphorus", "Potassium"],
                  y: current_values,
                  type: "bar",
                  name: "Current",
                  marker: { color: "blue" }
                },
                {
                  x: ["Nitrogen", "Phosphorus", "Potassium"],
                  y: recommended_nutrients,
                  type: "bar",
                  name: "Recommended",
                  marker: { color: "orange" }
                }
              ]}
              layout={{
                barmode: "group",
                title: "Nutrient Comparison",
                yaxis: { title: "Values" },
                xaxis: { title: "Nutrients" },
                width: 550
              }}
            />
          </Grid>
        )}
        {isPredictReady && (
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Grid item xs={12} style={{ marginTop: "1.25rem" }}>
              <Typography
                style={{
                  color: "rgba(0, 0, 0, 0.80)",
                  fontSize: "1rem",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal"
                }}
              >
                Nutrient Tables
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "2rem" }}>
              <DataGrid
                getRowId={data => data.Nutrient + data.Current}
                rows={predict.nutrients_table}
                columns={columnsNutrientTable}
                rowCount={100}
                style={{ height: 350}}
                paginationMode="server"
                components={{ Toolbar: GridToolbar }}
              />
            </Grid>
          </Grid>
        )}
        {isPredictReady && (
          <Grid item xs={12} style={{ marginTop: "0.5rem" }}>
            <Box
              sx={{
                position: "relative",
                display: "inline-block",
                border: "1px solid #828282",
                borderRadius: "0.25rem",
                overflow: "hidden"
              }}
            >
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        backgroundColor: "#EBEBEB",
                        gap: "0.625rem",
                        borderRight: "1px solid #828282",
                        borderBottom: "1px solid #828282"
                      }}
                    >
                      Nutrients Required For Land
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem",
                        borderBottom: "1px solid #828282"
                      }}
                    >
                      Hectares of Land: {input}
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem",
                        borderBottom: "1px solid #828282"
                      }}
                    >
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem",
                        borderBottom: "1px solid #828282"
                      }}
                    >
                    </TableCell>
                    
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem",
                        borderBottom: "1px solid #828282",
                        backgroundColor: "#EBEBEB",
                        borderRight: "1px solid #828282"
                      }}
                    >
                      Total Required Nutrients (for {input} hectares)
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem",
                        borderBottom: "1px solid #828282"
                      }}
                    >
                      Nitrogen: {predict.total_required_nutrients[0]}kg
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem",
                        borderBottom: "1px solid #828282"
                      }}
                    >
                      Phosphorus: {predict.total_required_nutrients[1]}kg
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem",
                        borderBottom: "1px solid #828282"
                      }}
                    >
                      Potassium: {predict.total_required_nutrients[2]}kg
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem",
                        borderBottom: "1px solid #828282",
                        backgroundColor: "#EBEBEB",
                        borderRight: "1px solid #828282"
                      }}
                    >
                      Total Excess Nutrients (for {input} hectares)
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem",
                        borderBottom: "1px solid #828282"
                      }}
                    >
                      Nitrogen: {predict.total_excess_nutrients[0]}kg
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem",
                        borderBottom: "1px solid #828282"
                      }}
                    >
                      Phosphorus: {predict.total_excess_nutrients[1]}kg
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem",
                        borderBottom: "1px solid #828282"
                      }}
                    >
                      Potassium: {predict.total_excess_nutrients[2]}kg
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        padding: "0.625rem 0.5rem",
                        alignItems: "center",
                        gap: "0.625rem",
                        backgroundColor: "#EBEBEB",
                        borderRight: "1px solid #828282"
                      }}
                    >
                      Recommendations
                    </TableCell>
                    {predict.recommendations.map((recommend, index) => {
            return (
              <TableCell
                index={index}
                style={{
                    padding: "0.625rem 0.5rem",
                    alignItems: "center",
                    gap: "0.625rem"
                  }}
              >
                <Typography>{recommend}</Typography>
              </TableCell>
            );
          })}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Grid>
        )}
      </Grid>

    </Container>
  );
};

export default NutrientCalculator;
