import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "shards-react";
import Plot from "react-plotly.js";

const YieldPrediction = () => {
  const [crop, setSelectedCrop] = useState("Soyabean");
  const [states, setStates] = useState({});
  const [currentstate, setCurrentState] = useState("");
  const [district, setDistrict] = useState({});
  const [currentdistrict, setCurrentdistrict] = useState("");
  const [yieldPrediction, setYieldPrediction] = useState("");

  let crops = [
    {
      id: 1,
      name: "Soyabean"
    }
  ];

  const handleInputChange = e => {
    setSelectedCrop(e.target.value);
  };
  const handleInputChangeState = e => {
    setCurrentState(e.target.value);
  };
  const handleInputChangeDistrict = e => {
    setCurrentdistrict(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/get/soyabean/yield/prediction/state`,
        {
          Crop: crop
        }
      );
      if (response.data.status == "success") {
        setStates(response.data.crop_type_state_data);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/get/soyabean/yield/prediction/state/district`,
        {
          Crop: crop,
          State: currentstate
        }
      );
      if (response.data.status == "success") {
        setDistrict(response.data.crop_type_district_data);
      }
    };

    fetchData();
  }, [currentstate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/get/soyabean/yield/prediction`,
          {
            Crop: crop,
            State: currentstate,
            District: currentdistrict
          }
        );
        if (response.data.status == "success") {
          setYieldPrediction(response.data);
        }
      } catch (err) {
        setYieldPrediction("");
      }
    };

    fetchData();
  }, [currentdistrict]);

  return (
    <Container
      fluid
      className="main-content-container px-4 py-4"
      style={{ backgroundColor: "white" }}
    >
      {/* <Row noGutters className="page-header py-2">
        <Col>
          <FormControl style={{ marginTop: "12px" }}>
            <InputLabel id="asset-select-label">Crop Type</InputLabel>

            <Select
              labelId="asset-select-label"
              id="asset-select"
              name="cropType"
              value={crop}
              onChange={e => handleInputChange(e)}
              label="Select category"
              style={{ width: 165 }}
            >
              {crops.map(crop => {
                return (
                  <MenuItem key={crop.id} value={crop.name}>
                    {crop.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Col>
      </Row> */}
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4} md={4}>
          <Grid item xs={12} style={{ marginBottom: "0.5rem" }}>
            <Typography
              style={{
                color: "rgba(0, 0, 0, 0.80)",
                fontSize: "1.25rem",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal"
              }}
            >
              Soil type
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={crop}
              name="soiltype"
              style={{ width: "20rem", height: "4.5rem" }}
              onChange={e => handleInputChange(e)}
            >
              {crops.map(crop => {
                return (
                  <MenuItem key={crop.id} value={crop.name}>
                    {crop.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4} md={4}>
          <Grid item xs={12} style={{ marginBottom: "0.5rem" }}>
            <Typography
              style={{
                color: "rgba(0, 0, 0, 0.80)",
                fontSize: "1.25rem",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal"
              }}
            >
              Select State
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Select
              labelId="asset-select-label"
              id="asset-select"
              name="stateType"
              value={currentstate}
              style={{ width: "20rem", height: "4.5rem" }}
              onChange={e => handleInputChangeState(e)}
            >
              {Object.values(states).map(state => {
                return (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>
        {currentstate && (
          <Grid item xs={12} lg={4} md={4}>
            <Grid item xs={12} style={{ marginBottom: "0.5rem" }}>
              <Typography
                style={{
                  color: "rgba(0, 0, 0, 0.80)",
                  fontSize: "1.25rem",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal"
                }}
              >
                Select District
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Select
                labelId="asset-select-label"
                id="asset-select"
                name="districtType"
                value={currentdistrict}
                onChange={e => handleInputChangeDistrict(e)}
                style={{ width: "20rem", height: "4.5rem" }}
              >
                {Object.values(district).map(district => {
                  return (
                    <MenuItem key={district} value={district}>
                      {district}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
        )}
        {yieldPrediction && yieldPrediction.historical_data && (
          <Grid item xs={12}>
            <Grid item xs={12} style={{ marginTop: "2rem" }}>
                            <Typography
                              style={{
                                color: "rgba(0, 0, 0, 0.80)",
                                fontSize: "1.25rem",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "normal"
                              }}
                            >
                              Soybean Production Trend in {currentstate}, {currentdistrict}
                            </Typography>
                          </Grid>
                        <Grid item xs={12}>  
            <Plot
              data={[
                {
                  x: yieldPrediction.historical_data.map(
                    data => data.Crop_Year
                  ),
                  y: yieldPrediction.historical_data.map(
                    data => data.Production
                  ),
                  mode: "lines",
                  type: "scatter",
                  name: "Historical"
                },
                {
                  x:
                    yieldPrediction.future_data &&
                    Object.values(yieldPrediction.future_data.Crop_Year || {}),
                  y:
                    yieldPrediction.future_data &&
                    Object.values(yieldPrediction.future_data.Production || {}),
                  mode: "lines",
                  type: "scatter",
                  name: "Future",
                  line: { dash: "dash" }
                }
              ]}
              layout={{
                title: ``,
                xaxis: { title: "Year" },
                yaxis: { title: "Production (1000 tons)" }
              }}
              style={{ width: "100%", height: "100%" }}
            />
            </Grid>
          </Grid>
        )}
        {yieldPrediction == "" && (
          <Grid item xs={12}>
            No Data available for yield prediction trends
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default YieldPrediction;
